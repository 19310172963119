import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Delete, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from '../../services/try';

//internals
import DeviceImg from "../../assets/images/devices.png";

//externals
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------

const ManageDevice = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDeviceHistory, setOpenDeviceHistory] = useState(false);

    const [device, setDevice] = useState([]);
    const [selectedDeviceHistorytoView, setSelectedDeviceHistorytoView] = useState([]);
    const [selectedDevicetoView, setSelectedDevicetoView] = useState([]);


    const [searchTerm, setSearchTerm] = useState('');

    const loadDeviceList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("compId");
            physicianId = secureLocalStorage.getItem("UUID");
        }
        console.log(companyId, '********************');
        ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
            .then((response) => {
                console.log(response.data.device);
                if (response.data.device != undefined) {
                    setDevice(response.data.device);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setDevice([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setDevice([]);
            })
    }

    useEffect(() => {
        loadDeviceList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const filteredDevices = device.filter((device) =>
        device.devicename.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.decmacid.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleDeleteDevice = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/checkForConnectedBeds")
            .then((response) => {
                console.log(response.data);
                if (response.data == true) {
                    swal({
                        title: "Cannot Delete",
                        text: `This ${secureLocalStorage.getItem("DeviceName")} cannot be deleted at the moment as they are currently connected.`,
                        icon: "warning",
                        buttons: {
                            ok: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                        },
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                var rolename = secureLocalStorage.getItem("rolename");
                                if (rolename === "Tenant") {
                                    ApiCalls.delete("deletedevices/" + deviceId)
                                        .then((response) => {
                                            console.log(response.data);
                                            if (response.data.status.message === "Success") {
                                                swal("Deleted Successfully!", {
                                                    icon: "success",
                                                });
                                                loadDeviceList();
                                            }
                                        });
                                } else if (rolename === "Company") {
                                    ApiCalls.delete("deletedevicesByCompany/" + deviceId)
                                        .then((response) => {
                                            console.log(response.data);
                                            if (response.data.status.message === "Success") {
                                                swal("Deleted Successfully!", {
                                                    icon: "success",
                                                });
                                                loadDeviceList();
                                            }
                                        });
                                }

                            }
                        });
                }
            })


    }

    const handleEditDevice = (device) => {
        secureLocalStorage.setItem("deviceIdToEdit", device.decmacid);
        secureLocalStorage.setItem("deviceIdSelected", device.deviceuuid);
        setRedirectToEdit(true);
    }

    const handleClickDevice = (deviceDetails) => {
        console.log(deviceDetails.decmacid);
        secureLocalStorage.setItem("deviceIdToEdit", deviceDetails.decmacid)
        secureLocalStorage.setItem("deviceIdSelected", deviceDetails.deviceuuid);
        setSelectedDevicetoView(deviceDetails);
        setOpenDeviceHistory(true);
    }

    if (openDeviceHistory) {
        return (<Redirect to={'/devicehistory'} />);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editdevice'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registerdevice'} />)
    }


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label={"Manage " + secureLocalStorage.getItem("DeviceName")} />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureLocalStorage.getItem("rolename") === "Physician" ? "none" : ""
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setRedirectToForm(true)}>
                        New {secureLocalStorage.getItem("DeviceName")}
                    </Button>
                </Stack>


                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureLocalStorage.getItem("DeviceName") + " List"}
                        </Typography>}
                        action={
                            <TextField
                                label={"Search " + secureLocalStorage.getItem("DeviceName")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredDevices.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredDevices.map((devices) => (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{
                                                maxWidth: 300,
                                                boxShadow: 3,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center items horizontally
                                                '&:hover': {
                                                    border: '1px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                }
                                            }}>
                                                <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={devices.devicename} />
                                                <Avatar
                                                    variant='rounded'
                                                    alt={devices.devicename}
                                                    src={(devices.deviceimageName && devices.deviceimageName !== "null") ? devices.deviceimageName : DeviceImg}
                                                    sx={{
                                                        width: 150,
                                                        height: 150,
                                                        objectFit: 'contain',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => handleClickDevice(devices)}
                                                />
                                                <CardContent
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => handleClickDevice(devices)}
                                                >
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        Device Id : {devices.decmacid}
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                        Model Name : {devices.modelSchema == undefined ? "" : devices.modelSchema[0].modelName}
                                                    </Typography>
                                                    {devices.modelSchema !== undefined && (
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Model Number: {devices.modelSchema[0]?.modelNumber || "Not provided"}
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditDevice(devices)} startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteDevice(devices.deviceuuid)} startIcon={<Delete />}>Delete</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No {secureLocalStorage.getItem("DeviceName")} Found
                                </center>
                            )
                        }

                    </CardContent>
                </Card>

            </Container>
        </div>
    )
}

export default ManageDevice
