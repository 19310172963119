import React, { useCallback, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import Iconify from '../../components/iconify/Iconify';
import Preview from "../../layout/Preview";

//mui-components
import { CloudUpload, Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    FormControl,
    FormHelperText,
    ListItemIcon,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import Papa from 'papaparse';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDropzone } from 'react-dropzone';
import secureLocalStorage from 'react-secure-storage';
import { ReactSVG } from 'react-svg';
import swal from 'sweetalert';

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ChestSensorIcon = () => {
    const xml = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
      >
        <path
          d="M20.08,0H5.92C2.65,0 0,2.65 0,5.92v34.16C0,43.35 2.65,46 5.92,46h14.16c3.27,0 5.92,-2.65 5.92,-5.92V5.92C26,2.65 23.35,0 20.08,0zM23,28.21c0,1.54 -1.25,2.79 -2.79,2.79H5.79C4.25,31 3,29.75 3,28.21V17.79C3,16.25 4.25,15 5.79,15h14.42c1.54,0 2.79,1.25 2.79,2.79V28.21z"
          fill="#FFFFFF"
        />
        <path
          d="M20.21,31H5.79C4.25,31 3,29.75 3,28.21V17.79C3,16.25 4.25,15 5.79,15h14.42c1.54,0 2.79,1.25 2.79,2.79v10.42C23,29.75 21.75,31 20.21,31z"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          fill-opacity="0.59"
        />
      </svg>
    `;

    return <ReactSVG xml={xml} />;
};

const BPSensorIcon = () => {
    const xml = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.2"
        height="48.7"
        viewBox="0 0 28.2 48.7"
      >
        <path
          d="M25.02,42.55h-7.4V5.98h7.4c1.73,0 3.13,1.4 3.13,3.13v30.3C28.16,41.14 26.75,42.55 25.02,42.55z"
          fill="#FFFFFF"
        />
        <path
          d="M13.22,0.02H4.44c-2.43,0 -4.4,1.97 -4.4,4.4v39.85c0,2.43 1.97,4.4 4.4,4.4h8.78c2.43,0 4.4,-1.97 4.4,-4.4V4.42C17.62,1.99 15.65,0.02 13.22,0.02zM7.59,44.16c0,1.73 -1.4,3.13 -3.13,3.13H4.33c-1.73,0 -3.13,-1.4 -3.13,-3.13V35.3c0,-1.73 1.4,-3.13 3.13,-3.13h0.13c1.73,0 3.13,1.4 3.13,3.13V44.16z"
          fill="#FFFFFF"
        />
        <path
          d="M4.46,32.17H4.33c-1.73,0 -3.13,1.4 -3.13,3.13v8.86c0,1.73 1.4,3.13 3.13,3.13h0.13c1.73,0 3.13,-1.4 3.13,-3.13V35.3C7.59,33.58 6.19,32.17 4.46,32.17"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          fill-opacity="0.59"
        />
      </svg>
    `;

    return <ReactSVG xml={xml} />;
};

const LimbSensorIcon = () => {
    const xml = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="49"
        viewBox="0 0 21 49"
      >
        <!-- ... (Previous code) ... -->
        <path
          d="M15.84,13.58H5.17c-1.94,0 -3.52,1.58 -3.52,3.52v3.14c0,1.94 1.58,3.52 3.52,3.52h10.67c1.94,0 3.52,-1.58 3.52,-3.52v-3.14C19.36,15.16 17.79,13.58 15.84,13.58"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          fill-opacity="0.59"
        />
        <path
          d="M14.84,1.34H6.19c-0.39,0 -0.71,0.32 -0.71,0.71v2.96c0,0.39 0.32,0.71 0.71,0.71h8.65c0.39,0 0.71,-0.32 0.71,-0.71V2.05C15.55,1.66 15.23,1.34 14.84,1.34"
          stroke="#FFFFFF"
          fill="#FFFFFF"
          fill-opacity="0.59"
        />
      </svg>
    `;

    return <ReactSVG xml={xml} />;
};
//-----------------------------------------------------------------------------

const AddSensor = () => {
    const [sensorMacId, setSensorMacId] = useState('');
    const [sensorName, setSensorName] = useState('');
    const [sensorType, setSensorType] = useState('');
    const [fileError, setFileError] = useState(false);
    const [fileMessage, setFileMessage] = useState('')

    const [csvFile, setCsvFile] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [hover, setHover] = useState(false);

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleCancel = () => {
        setRedirectToList(true);
    }

    const handleFileChange = (acceptedFiles) => {
        const file = acceptedFiles[0];

        // Check if the selected file is a CSV file
        if (file && file.type === 'text/csv') {
            setCsvFile(file);
            setFileError(false); // Reset the file error if it was previously set

            // Read the CSV file
            Papa.parse(file, {
                complete: (result) => {
                    console.log('Parsed CSV result:', result.data);
                    swal({
                        title: "Save Sensor Details?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((saveData) => {
                        if (saveData) {
                            result.data.forEach((sensorData) => {
                                const data = {
                                    sensorName: sensorData.sensorname,
                                    sensormacid: sensorData.sensormacid,
                                    sensorType: sensorData.sensorType,
                                    sensorImg: sensorData.sensorImg == "" ? "" : sensorData.sensorImg,
                                    tenantid: secureLocalStorage.getItem("UUID")
                                };

                                // API call to save each sensor
                                ApiCalls.register("savesensordetails", data)
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.message === "Success") {
                                            swal("Sensor Successfully Registered", {
                                                icon: "success",
                                            });
                                            setRedirectToList(true);
                                        } else if (response.data.status.details === "Sensors Already Registered") {
                                            swal("Some Sensors Already Registered", {
                                                icon: "warning",
                                            });
                                            setRedirectToList(true);
                                        }
                                        // Handle success if needed
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        // Handle error if needed
                                    });
                            });
                        }

                    })

                },
                header: true, // If the CSV file has a header row
            });
        } else {
            // Handle the case when a non-CSV file is selected
            setFileError(true);
            setFileMessage('Please select a valid CSV file.')
            // You can show a message or take other actions as needed
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv', // Specify that only CSV files are accepted
    });

    const handleRegister = () => {
        // Reset all error states
        setNameError(false);
        setMacIdError(false);
        setDeviceError(false);
        setFileError(false);

        // Check if either both text fields or the CSV file is provided
        const isTextFieldsEmpty = !sensorName.trim() && !sensorMacId.trim() && !sensorType;
        const isFileEmpty = !csvFile;

        if (isTextFieldsEmpty && isFileEmpty) {
            // Set errors for both text fields and the CSV file
            setNameError(true);
            setMacIdError(true);
            setDeviceError(true);
            setFileError(true);
            return;
        }

        // Validate text fields if provided
        if (!sensorName.trim()) {
            setNameError(true);
        }
        if (!sensorMacId.trim()) {
            setMacIdError(true);
        }
        if (!sensorType) {
            setDeviceError(true);
        }

        // Validate CSV file if provided
        if (isFileEmpty) {
            setFileError(true);
        }

        // Continue with your registration logic if at least one set of fields is valid
        if ((sensorName.trim() && sensorMacId.trim() && sensorType) || !isFileEmpty) {

            const data = {
                sensorName: sensorName,
                sensormacid: sensorMacId,
                sensorType: sensorType,
                sensorImg: uploadedImage == null ? "" : uploadedImage,
                tenantid: secureLocalStorage.getItem("UUID")
            }

            ApiCalls.register("savesensordetails", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Sensor Successfully Registered", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else if (response.data.status.details === "Sensors Already Registered") {
                        swal("Sensor Already Registered", {
                            icon: "warning",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };



    const onChangeX = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBlur = (field) => {
        switch (field) {
            case 'sensorName':
                setNameError(!sensorName.trim());
                break;
            case 'sensorMacId':
                setMacIdError(!sensorMacId.trim());
                break;
            case 'sensorType':
                setDeviceError(!sensorType);
                break;
            default:
                break;
        }
    };

    if (redirectToList) {
        return (<Redirect to={"/managesensor"} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managesensor">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Manage Sensor
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Add Sensor" />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            Add Sensor
                        </Typography>}
                    />
                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ ml: 10, mt: 10 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="rounded"
                                                src={uploadedImage}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 200,
                                                    height: 200,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="material-symbols:door-sensor-outline" color="#4481eb" width="100" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={onChangeX}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 6">
                                    <Item>
                                        <Stack direction="column" spacing={2} justifyContent="space-between">

                                            <TextField
                                                label="Sensor Name"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorName}
                                                onBlur={() => handleBlur('sensorName')}
                                                onChange={(e) => setSensorName(e.target.value)}
                                                error={nameError}
                                                helperText={nameError && "Please enter a valid Sensor Name"}
                                            />
                                            <TextField
                                                label="Sensor Mac ID"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorMacId}
                                                onBlur={() => handleBlur('sensorMacId')}
                                                onChange={(e) => setSensorMacId(e.target.value)}
                                                error={macIdError}
                                                helperText={macIdError && "Please enter a valid Sensor Mac ID"}
                                            />

                                            {/* Select for SensorType */}
                                            <TextField
                                                select
                                                label="Sensor Type"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={sensorType}
                                                onBlur={() => handleBlur('sensorType')}
                                                onChange={(e) => setSensorType(e.target.value)}
                                                error={deviceError}
                                                helperText={deviceError && "Please select a Sensor Type"}
                                            >
                                                <MenuItem value="Chest">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    Chest Sensor
                                                </MenuItem>
                                                <MenuItem value="Limb">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    Limb Sensor
                                                </MenuItem>
                                                <MenuItem value="BP">
                                                    <ListItemIcon>
                                                        <Icon icon={'material-symbols:sensor-door-outline-rounded'} />
                                                    </ListItemIcon>
                                                    BP Sensor
                                                </MenuItem>
                                            </TextField>

                                            <Typography variant="subtitle2" gutterBottom>
                                                OR
                                            </Typography>

                                            <FormControl fullWidth margin="normal">
                                                <div
                                                    {...getRootProps()}
                                                    style={{
                                                        border: '2px dashed #5C9CFE',
                                                        borderRadius: '8px',
                                                        padding: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <CloudUpload style={{ fontSize: 48, marginBottom: '16px' }} />
                                                    <div>
                                                        <Typography variant="subtitle2" display="block" color={(theme) => theme.palette.grey[600]} gutterBottom>
                                                            Drag and drop a CSV file here
                                                        </Typography>
                                                        <Typography variant="subtitle2" display="block" color={(theme) => theme.palette.grey[600]} gutterBottom>
                                                            or click to select one
                                                        </Typography>
                                                        {csvFile && (
                                                            <Box display="flex" alignItems="center">
                                                                <Typography variant="subtitle2" display="inline" gutterBottom>
                                                                    {'\u00A0Selected File\u00A0:\u00A0'}
                                                                </Typography>
                                                                <Typography variant="subtitle2" display="inline" color="primary" gutterBottom>
                                                                    {csvFile.name}
                                                                </Typography>
                                                            </Box>
                                                        )}

                                                    </div>
                                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                                </div>
                                                <FormHelperText sx={{
                                                    fontStyle: "oblique",
                                                    fontWeight: 'bold',
                                                    fontSize: '0.875rem',
                                                    color: fileError ? 'red' : (theme) => theme.palette.grey[600],
                                                    marginTop: '8px',
                                                }}>
                                                    {fileError ? fileMessage : 'Upload a CSV file to import Sensor Details'}
                                                </FormHelperText>
                                            </FormControl>
                                            <Stack direction="row" spacing={2} justifyContent="space-around" >
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    type="submit"
                                                    color="error"
                                                    variant="outlined"
                                                    onMouseDown={handleMouseDown}
                                                    onClick={handleCancel}
                                                    sx={{
                                                        borderRadius: "12px",
                                                    }}
                                                    startIcon={
                                                        <Iconify icon={'material-symbols:cancel-presentation'} />
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleRegister}
                                                    onMouseDown={handleMouseDown}
                                                    sx={{
                                                        borderRadius: "12px",
                                                    }}
                                                    endIcon={
                                                        <Iconify icon={'mdi:content-save-check'} />
                                                    }
                                                >
                                                    Register
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default AddSensor
