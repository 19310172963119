import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//internals
import DeviceImg from "../../assets/images/devices.png";
import Preview from "../../layout/Preview";

//mui-components
import {
    ChevronLeftRounded,
    ChevronRightRounded,
    EdgesensorHighTwoTone,
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { emphasize, styled, useTheme } from "@mui/material/styles";

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import secureLocalStorage from "react-secure-storage";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import swal from "sweetalert";
// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const MaterialArrow = ({ className, onClick, direction }) => {
    const arrowStyle = {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        [direction]: direction === "prev" ? "-35px" : "auto",
        right: direction === "next" ? "0" : "10px",
    };

    return (
        <div className={className} onClick={onClick} style={arrowStyle}>
            {direction === "prev" ? (
                <ChevronLeftRounded color="primary" fontSize="large" />
            ) : (
                <ChevronRightRounded color="primary" fontSize="large" />
            )}
        </div>
    );
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 65;

const StyledCard = styled("div")(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(2),
    "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
    "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
        overflow: "visible",
    },
    "& .apexcharts-legend": {
        height: LEGEND_HEIGHT,
        alignContent: "center",
        position: "relative !important",
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

const DeviceHistory = () => {
    const sliderRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [deviceId, setDeviceId] = useState("")
    const [paramName, setParamName] = useState("");

    const [open, setOpen] = useState(null);
    const [openLimitFilter, setOpenLimitFilter] = useState(null);
    const [openParamFilter, setOpenParamFilter] = useState(null);

    const [dynamicFields, setDynamicFields] = useState({});
    const [customFieldList, setCustomFieldList] = useState([]);
    const [sensors, setSensor] = useState([]);
    const [selectedParamFilter, setSelectedParamFilter] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [selectedSensor, setSelectedSensor] = useState([]);
    const [assignedPatients, setAssignedPatients] = useState([]);
    const [deviceHistory, setDeviceHistory] = useState([]);

    const [openDialogIndex, setOpenDialogIndex] = useState(false);
    const [loadpatients, setLoadPatients] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);

    const [filterOptions, setFilterOptions] = useState([
        {
            value: 100,
            label: '100 Rows',
        },
        {
            value: 1000,
            label: '1000 Rows',
        },
        {
            value: 10000,
            label: '10000 Rows',
        }
    ]);
    const [selectedLimitFilter, setSelectedLimitFilter] = useState(filterOptions[0]);

    const getMeasurementParameter = (deviceId) => {
        ApiCalls.getEmbeddedData("device/" + deviceId + "/getmeasurementParameter")
            .then((response) => {
                console.log(response.data);
                if (response.data.cms !== undefined) {
                    console.log(response.data.cms[0]);
                    setParamName(response.data.cms[0].param_name);
                    const options = response.data.cms.map((d) => ({
                        label: d.param_name,
                        value: d.param_id,
                    }));
                    const allOption = { label: "All", value: "all" };
                    options.unshift(allOption);
                    setParameters(options);
                    setSelectedParamFilter(options[0]);
                }
            })
    }

    const getDeviceData = (deviceId, limit, paramName) => {

        setLoading(true);
        ApiCalls.getEmbeddedData("device/" + deviceId + "/parameter/" + paramName + "/fetchembeddeddata/" + limit)
            .then((response) => {
                console.log(response.data);
                if (response.data.cms != undefined) {
                    setMeasurements(response.data.cms);
                    setLoading(false);
                } else {
                    setMeasurements([])
                    setLoading(false);
                }
            }).catch((err) => {
                console.log(err);
                setMeasurements([]);
                setLoading(false)
            })
    }

    // Filtered measurements based on search term
    const filteredMeasurements = measurements.filter((measure) => {
        const searchTermString = searchTerm.trim();
        // Check if measurementid is defined before converting to string
        const measurementIdString = measure.measurementid ? measure.measurementid.toString() : '';
        return measurementIdString.includes(searchTermString);
    });


    const handleCardClick = (sensors) => {
        setSelectedSensor(sensors);
        console.log(sensors);
        setOpenDialogIndex(true);
    };

    // Function to close a specific dialog
    const handleCloseDialog = () => {
        setOpenDialogIndex(false);
    };

    // Function to close the dialog
    const handleMoveLeft = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };


    const loadDeviceAsignedPatients = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/getpatientsassigned").then(
            (response) => {
                console.log(response.data);
                if (response.data.patient !== undefined) {
                    setAssignedPatients(response.data.patient);
                }
            }
        );
        // if (response.data.patient != undefined) {
        //     setAssignedPatients(response.data.patient);
        //     setLoadPatients(false);
        // } else {
        //     setAssignedPatients([]);
        //     setLoadPatients(false)
        // }
    };
    useEffect(() => {
        var deviceId = secureLocalStorage.getItem("deviceIdToEdit");
        console.log(deviceId);
        loadDeviceAsignedPatients(deviceId);
    }, []);

    useEffect(() => {
        var deviceId = secureLocalStorage.getItem("deviceIdSelected");
        var tenantId = secureLocalStorage.getItem("tenantId");

        ApiCalls.getdetails("tenant/" + tenantId + "/deviceid/" + deviceId + "/showdevicehistory")
            .then((response) => {
                console.log(response.data.device[0]);
                if (response.data.device !== undefined) {
                    setDeviceHistory(response.data.device[0]);
                    setDeviceId(response.data.device[0].deviceUUId)
                    // getPatientAssigned(response.data.device[0].deviceUUId);
                    getDeviceData(response.data.device[0].deviceUUId, selectedLimitFilter.value, "all");
                    getMeasurementParameter(response.data.device[0].deviceUUId);
                } else {
                    setDeviceHistory([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching device details:", error);
                setDeviceHistory([]);
            });
    }, []);

    useEffect(() => {
        ApiCalls.getdetails("listallsensors")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor !== undefined) {
                    setSensor(response.data.sensor);
                }
            })
            .catch((error) => {
                console.error("Error fetching device details:", error);
            });
    }, []);

    useEffect(() => {
        ApiCalls.getdetails("listallsensors")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor !== undefined) {
                    setSensor(response.data.sensor);
                }
            })
            .catch((error) => {
                console.error("Error fetching device details:", error);
            });
    }, []);

    {
        /* -----------------------Slider Settings----------------------------------------------------------------------------------- */
    }

    const settings = {
        // sets custom arrow components
        prevArrow: <MaterialArrow direction="prev" />,
        nextArrow: <MaterialArrow direction="next" />,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleOpenParamFilters = (e) => {
        setOpenParamFilter(e.currentTarget);
    };

    const handleCloseParamFilters = () => {
        setOpenParamFilter(null);
    };

    const handleParamFilterChange = (filter) => {
        console.log("Select Filter");
        console.log(filter);
        setSelectedParamFilter(filter);
        getDeviceData(deviceId, selectedLimitFilter.value, filter.label);
        handleCloseParamFilters();
    };

    const handleOpenLimitFilters = (e) => {
        setOpenLimitFilter(e.currentTarget);
    };

    const handleCloseLimitFilters = () => {
        setOpenLimitFilter(null);
    };

    const handleLimitFilterChange = (filter) => {
        setSelectedLimitFilter(filter);
        getDeviceData(deviceId, filter.value, selectedParamFilter.label);
        handleCloseLimitFilters();
    }

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRefresh = () => {
        getDeviceData(deviceId, selectedLimitFilter.value, selectedParamFilter.label);
    }

    const handleDeleteCompany = (companyId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                var tenantId = secureLocalStorage.getItem("tenantId");

                ApiCalls.delete(
                    "organizations/0/tenants/" +
                    tenantId +
                    "/companies/" +
                    companyId +
                    "/deleteCompany"
                ).then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Deleted Successfully!", {
                            icon: "success",
                        });
                    }
                });
            }
        });
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button
                                variant="text"
                                startIcon={<Home fontSize="small" />}
                                onMouseDown={handleMouseDown}
                                sx={{ mr: 1 }}
                            >
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button
                                variant="text"
                                startIcon={
                                    <Icon icon={"tabler:devices-plus"} fontSize="small" />
                                }
                                onMouseDown={handleMouseDown}
                                sx={{ mr: 1 }}
                            >
                                {"Manage " + secureLocalStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb
                            label={secureLocalStorage.getItem("DeviceName") + " History"}
                        />
                    </Breadcrumbs>
                </Stack>

                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h5" gutterBottom>
                                {secureLocalStorage.getItem("DeviceName") + " History"}
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Avatar
                                    variant="rounded"
                                    alt={deviceHistory.deviceName}
                                    src={
                                        deviceHistory.deviceimageName == undefined ||
                                            deviceHistory.deviceimageName === "null"
                                            ? DeviceImg
                                            : deviceHistory.deviceimageName
                                    }
                                    sx={{ width: "40%", height: "auto", objectFit: "contain" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        {deviceHistory.deviceName && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Device Name :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.deviceName.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.devmacid && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Device MacId :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.devmacid}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.osVersion && deviceHistory.osVersion !== "NULL" && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    OS Version :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.osVersion}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {deviceHistory.createdOn && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Registered On :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {new Date(parseInt(deviceHistory.createdOn)).toISOString().split('T')[0]}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.companyName && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Enrolled To :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.companyName === "NULL" ? "Not Enrolled" : deviceHistory.companyName}
                                                </Typography>
                                            </Box>
                                        )}
                                        {deviceHistory.enrolledDate && (
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                    Enrolled On :
                                                </Typography>
                                                <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                    {deviceHistory.enrolledDate !== "NULL" ? deviceHistory.enrolledDate.split(' ')[0] : "--"}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{ mt: 1 }}>
                    <CardHeader
                        title={
                            <Typography variant="h5" gutterBottom>
                                Measurement Data
                            </Typography>
                        }
                        action={
                            <Box sx={{
                                display: 'flex', alignItems: 'center'
                            }}>
                                {/* Search Field */}
                                <TextField
                                    sx={{
                                        height: 50
                                    }}
                                    color='primary'
                                    label={"Search Measurement Id"}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />

                                {/* Tooltip: Filter Parameter */}
                                <Tooltip title="Filter Parameter">
                                    <Button
                                        color="primary"
                                        className="ml-2"
                                        variant="contained"
                                        onClick={handleOpenParamFilters}
                                        endIcon={<Icon icon="mi:filter" />}
                                    >
                                        {selectedParamFilter.label}
                                    </Button>
                                </Tooltip>

                                <Popover
                                    open={Boolean(openParamFilter)}
                                    anchorEl={openParamFilter}
                                    onClose={handleCloseParamFilters}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    PaperProps={{
                                        sx: {
                                            p: 1,
                                            mt: 1.5,
                                            ml: 0.75,
                                            width: 180,
                                            "& .MuiMenuItem-root": {
                                                px: 1,
                                                typography: "body2",
                                                borderRadius: 0.75,
                                            },
                                        },
                                    }}
                                >
                                    <Stack spacing={0.75}>
                                        {parameters.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                selected={option.value === selectedParamFilter.value}
                                                onClick={() => handleParamFilterChange(option)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Stack>
                                </Popover>

                                {/* Tooltip: Filter Limit */}
                                <Tooltip title="Filter Limit">
                                    <Button color='primary' variant='contained' className="ml-2" onClick={handleOpenLimitFilters} endIcon={<Icon icon="material-symbols:filter-alt" />}>
                                        {selectedLimitFilter.label}
                                    </Button>
                                </Tooltip>

                                {/* Tooltip: Refresh */}
                                <Tooltip title="Refresh">
                                    <Fab
                                        size="small"
                                        color='primary'
                                        className="ml-2 mr-2"
                                        onMouseDown={handleMouseDown}
                                        onClick={handleRefresh}

                                    >
                                        <Icon icon="mdi:file-document-refresh-outline" width="25" />
                                    </Fab>
                                </Tooltip>



                                {/* Popover Component */}
                                <Popover
                                    open={Boolean(openLimitFilter)}
                                    anchorEl={openLimitFilter}
                                    onClose={handleCloseLimitFilters}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{
                                        sx: {
                                            p: 1,
                                            mt: 1.5,
                                            ml: 0.75,
                                            width: 180,
                                            '& .MuiMenuItem-root': {
                                                px: 1,
                                                typography: 'body2',
                                                borderRadius: 0.75,
                                            },
                                        },
                                    }}
                                >
                                    <Stack spacing={0.75}>
                                        {filterOptions.map((option) => (
                                            <MenuItem key={option.value} selected={option.value === selectedLimitFilter.value} onClick={() => handleLimitFilterChange(option)}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Stack>
                                </Popover>

                            </Box>
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Measurement Id</TableCell>
                                            <TableCell>Param Id</TableCell>
                                            <TableCell>Param Name</TableCell>
                                            <TableCell>Measured Timestamp</TableCell>
                                            {/* Add more columns as needed */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredMeasurements.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Typography variant="body1">
                                                            No measurement data
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            filteredMeasurements.map((measurement, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{measurement.measurementid}</TableCell>
                                                    <TableCell>{measurement.param_id}</TableCell>
                                                    <TableCell>{measurement.param_name}</TableCell>
                                                    <TableCell>
                                                        {new Date(Number(measurement.actualtimestamp)).toLocaleString(undefined, {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            second: 'numeric',
                                                            fractionalSecondDigits: 3,
                                                            millisecond: 'numeric',
                                                            timeZoneName: 'short',
                                                        }).replace(/(\d+)\/(\d+)\/(\d+),/, '$2-$1-$3,')}
                                                    </TableCell>
                                                    {/* Add more cells with data if needed */}
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </CardContent>
                    <CardActions sx={{
                        float: "right"
                    }}>
                        {/* Button: Total Records */}
                        <Button className="ml-2" color='primary' variant='outlined' onMouseDown={handleMouseDown} sx={{
                            cursor: "text"
                        }}>
                            {loading ? <Icon icon={'eos-icons:three-dots-loading'} width="30" /> : (measurements[0]?.dataCount ? `Total Records: ${measurements[0].dataCount}` : null)}
                        </Button>
                    </CardActions>
                </Card>

            </Container>

            <Dialog
                open={openDialogIndex}
                onClose={handleCloseDialog}
                maxWidth="lg"
                fullWidth
                sx={{ width: "600px", margin: "auto" }}
            >
                <DialogTitle>Sensor Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: blue[900],
                                            width: 200,
                                            height: 200,
                                            margin: "auto",
                                            marginLeft: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 0,
                                        }}
                                        src={selectedSensor.sensorImg}
                                    >
                                        <EdgesensorHighTwoTone sx={{ fontSize: 58 }} />
                                    </Avatar>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6" gutterBottom>
                                Sensor Name:
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {selectedSensor.sensormacid}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Sensor ID:
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {selectedSensor.sensorId}
                            </Typography>

                            <Typography variant="h6" gutterBottom>
                                Sensor Type:
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {selectedSensor.sensorType}
                            </Typography>

                            <Typography variant="h6" gutterBottom>
                                CreatedOn:
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {selectedSensor.createdOn}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default DeviceHistory;
