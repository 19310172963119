import React, { useEffect, useRef, useState } from "react";

//mui-components
import { Close } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    List,
    ListItem,
    Paper,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";

//Dicom-viewer import
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {
        // Add custom headers here (e.g., auth tokens) if needed
    },
});

cornerstoneTools.external.cornerstone = cornerstone;
const ZoomTool = cornerstoneTools.ZoomTool;
cornerstoneTools.addTool(ZoomTool);
cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DiComViewerComponent = ({ open, onClose, imageDetails, patientDetails }) => {
    const [selectedSeries, setSelectedSeries] = useState(0);
    const elementRefs = useRef([]);
    const mainElementRef = useRef(null);
    const [thumbnails, setThumbnails] = useState([]);

    const handleSeriesClick = (index) => {
        setSelectedSeries(index);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleZoomIn = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.scale += 0.1;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleZoomOut = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.scale -= 0.1;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleRotateLeft = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.rotation -= 90;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleRotateRight = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.rotation += 90;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleResetView = () => {
        const image = cornerstone.getImage(mainElementRef.current);
        const defaultViewport = cornerstone.getDefaultViewportForImage(mainElementRef.current, image);
        cornerstone.setViewport(mainElementRef.current, {
            ...defaultViewport,
            voi: {
                windowWidth: image.windowWidth,
                windowCenter: image.windowCenter
            },
            invert: false
        });
    };

    const handleFlipHorizontal = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    const handleFlipVertical = () => {
        const viewport = cornerstone.getViewport(mainElementRef.current);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(mainElementRef.current, viewport);
    };

    useEffect(() => {
        if (open) {
            setSelectedSeries(0); // Reset the selected series to the first one
            const newThumbnails = imageDetails.map((detail, index) => {
                const blobUrl = URL.createObjectURL(detail.imageBlob);
                const imageId = `wadouri:${blobUrl}`;

                return new Promise((resolve) => {
                    cornerstone
                        .loadImage(imageId)
                        .then((image) => {
                            const canvas = document.createElement("canvas");
                            canvas.width = image.width;
                            canvas.height = image.height;
                            const context = canvas.getContext("2d");
                            cornerstone.renderToCanvas(canvas, image);
                            const thumbnailUrl = canvas.toDataURL("image/png");
                            resolve(thumbnailUrl);
                        })
                        .catch((error) => {
                            console.error(
                                `Error loading DICOM image for thumbnail (index ${index}):`,
                                error
                            );
                            resolve(null);
                        })
                        .finally(() => {
                            URL.revokeObjectURL(blobUrl);
                        });
                });
            });

            Promise.all(newThumbnails).then((urls) => {
                setThumbnails(urls.filter((url) => url !== null));
            });
        }
    }, [open, imageDetails]);

    useEffect(() => {
        if (open) {
            thumbnails.forEach((thumbnail, index) => {
                if (thumbnail && elementRefs.current[index]) {
                    const detail = imageDetails[index];
                    const blobUrl = URL.createObjectURL(detail.imageBlob);
                    const imageId = `wadouri:${blobUrl}`;

                    cornerstone.enable(elementRefs.current[index]);
                    cornerstone
                        .loadImage(imageId)
                        .then((image) => {
                            cornerstone.displayImage(elementRefs.current[index], image);
                        })
                        .catch((error) => {
                            console.error(
                                `Error loading DICOM image in sidebar (index ${index}):`,
                                error
                            );
                        });
                }
            });

            if (imageDetails[0] && mainElementRef.current) {
                const blobUrl = URL.createObjectURL(imageDetails[0].imageBlob);
                const imageId = `wadouri:${blobUrl}`;

                cornerstone.enable(mainElementRef.current);
                cornerstone
                    .loadImage(imageId)
                    .then((image) => {
                        cornerstone.displayImage(mainElementRef.current, image);
                    })
                    .catch((error) => {
                        console.error("Error loading DICOM image:", error);
                    });
            }
        }
    }, [open, thumbnails, imageDetails]);

    useEffect(() => {
        if (open && imageDetails[selectedSeries] && mainElementRef.current) {
            const blobUrl = URL.createObjectURL(imageDetails[selectedSeries].imageBlob);
            const imageId = `wadouri:${blobUrl}`;

            cornerstone.enable(mainElementRef.current);
            cornerstone
                .loadImage(imageId)
                .then((image) => {
                    cornerstone.displayImage(mainElementRef.current, image);
                })
                .catch((error) => {
                    console.error("Error loading DICOM image:", error);
                });
        }
    }, [open, selectedSeries, imageDetails]);

    useEffect(() => {
        if (!open) {
            setThumbnails([]);
            elementRefs.current.forEach((ref) => {
                if (ref) {
                    cornerstone.disable(ref);
                }
            });
            if (mainElementRef.current) {
                cornerstone.disable(mainElementRef.current);
            }
        }
    }, [open]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            onMouseDown={handleMouseDown}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Box sx={{ ml: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" component="div">
                                {(patientDetails.firstName ? patientDetails.firstName : '') +
                                    (patientDetails.lastName ? ' ' + patientDetails.lastName : '') || '--'}
                            </Typography>
                            <Typography variant="body2" component="div">
                                {patientDetails.patientId}
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box sx={{ display: "flex", height: "100vh", flexDirection: "column", overflow: "hidden" }}>
                    <Box sx={{ display: "flex", flex: 1, height: "100%", overflow: 'hidden', flexWrap: 'wrap' }}>
                        {/* Sidebar for Series */}
                        <Paper
                            sx={{
                                width: 200,
                                height: "100%",
                                overflowY: "auto",
                            }}
                        >
                            <List>
                                {thumbnails.map((thumbnail, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        selected={index === selectedSeries}
                                        onClick={() => handleSeriesClick(index)}
                                    >
                                        <Box
                                            ref={(el) => (elementRefs.current[index] = el)}
                                            sx={{
                                                width: "100%",
                                                height: "100px",
                                                backgroundColor: "black",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {/* Each sidebar item will display a DICOM image here */}
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                        {/* Main Viewing Area */}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: 'hidden',
                                position: "relative",
                                height: "100%",
                                minWidth: 0, // Ensure flexbox shrinks correctly
                            }}
                        >
                            <Box
                                ref={mainElementRef}
                                sx={{
                                    width: "90%",
                                    height: "calc(100% - 120px)",
                                    backgroundColor: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: 2,
                                    minWidth: 0, // Ensure flexbox shrinks correctly
                                }}
                            >
                                {/* The selected DICOM image will be displayed here */}
                            </Box>

                            {/* Bottom Toolbar for Actions */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "black",
                                    py: 1,
                                    width: "90%",
                                    minHeight: '60px',
                                    minWidth: 0, // Ensure flexbox shrinks correctly
                                }}
                            >
                                {/* Toolbar buttons (Zoom, Rotate, Flip, Reset) */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleZoomIn}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'tabler:zoom-in'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Zoom In</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleZoomOut}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'tabler:zoom-out'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Zoom Out</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleRotateLeft}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'ic:outline-rotate-left'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Left</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleRotateRight}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'ic:outline-rotate-right'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Right</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleFlipHorizontal}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'mdi:flip-horizontal'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Flip Horizontal</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleFlipVertical}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'mdi:flip-vertical'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Flip Vertical</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                    <IconButton
                                        onMouseDown={handleMouseDown}
                                        onClick={handleResetView}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon icon={'bx:reset'} />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: "white" }}>Reset View</Typography>
                                </Box>
                            </Box>
                        </Box>

                        {/* Details Box */}
                        <Paper
                            sx={{
                                width: { xs: "100%", sm: 300 }, // 100% width on small screens, 300px on larger screens
                                height: "100%",
                                p: 2,
                                backgroundColor: "#333",
                                overflowY: "auto",
                                boxSizing: "border-box",
                            }}
                        >
                            {imageDetails?.[selectedSeries]?.details ? (
                                <Box>
                                    <Typography variant="h6" sx={{ color: "white", mb: 4 }}>Image Details</Typography>
                                    <Typography variant="body1" sx={{ color: "white", fontWeight: "bold", mb: 2 }}>
                                        Scan Type: <span style={{ color: "yellow", fontWeight: "bold" }}>{imageDetails[selectedSeries].details.scanType || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Image Type: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.imageType || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Scan Date:<span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.scanDate || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Description: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.description || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Date of Birth: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.dob || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Body Part: <span style={{ color: "yellow", fontWeight: "bold", }}></span>{imageDetails[selectedSeries].details.bodyPart || 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Gender: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.gender || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Date: <span style={{ color: "yellow", fontWeight: "bold", }}>
                                            {imageDetails[selectedSeries].details.dateAndTime
                                                ? new Date(imageDetails[selectedSeries].details.dateAndTime).toLocaleDateString()
                                                : 'N/A'}
                                        </span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Study Time: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.studyTime || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Study Description: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.studyDescription || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Series Number: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.seriesNumber || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Institution Name: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.institutionName || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Photometric Interpretation: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.photometricInterpretation || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Image Position: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.imagePosition || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Slice Thickness: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.sliceThickness || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "white", mb: 2 }}>
                                        Pixel Spacing: <span style={{ color: "yellow", fontWeight: "bold", }}>{imageDetails[selectedSeries].details.pixelSpacing || 'N/A'}</span>
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography variant="body1" sx={{ color: "white" }}>
                                    No details available
                                </Typography>
                            )}
                        </Paper>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default DiComViewerComponent;

