import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
//components
import { blue } from "@mui/material/colors";
import QRCode from 'qrcode.react';
import Preview from '../../layout/Preview';
//mui-components
import {
    Close,
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from '@mui/icons-material';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import Iconify from '../../components/iconify/Iconify';
//services
import ApiCalls from "../../services/try";
//externals
import EdgesensorHighTwoToneIcon from '@mui/icons-material/EdgesensorHighTwoTone';
import secureLocalStorage from 'react-secure-storage';
import swal from "sweetalert";
// ----------------------------------------------------------------------
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    // const handleCheckboxChange = (sensorId, checked) => {
    //     if (checked) {
    //         setSelectedSensors((prevSelected) => [...prevSelected, sensorId]);
    //     } else {
    //         setSelectedSensors((prevSelected) => prevSelected.filter((id) => id !== sensorId));
    //     }
    // };
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


// ----------------------------------------------------------------------

const DeviceList = () => {

    const [company, setCompany] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(null);
    const [dynamicFields, setDynamicFields] = useState({});
    const [customFieldList, setCustomFieldList] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopups, setOpenPopups] = useState(false);
    const [openPopupss, setOpenPopupss] = useState(false);
    const [selectedSensorstoAssign, setSelectedSensorstoAssign] = useState([]);
    const [sensor, setSensor] = useState([]);



    const history = useHistory();



    const handleScanButtonClick = () => {
        // Open the popup when the "Scan" button is clicked
        setOpenPopup(true);
    };

    const handlesenseButtonClick = () => {
        // Open the popup when the "Scan" button is clicked
        setOpenPopups(true);
    };

    const handleAssignDevicetoPatient = () => {
        // Open the popup when the "Scan" button is clicked

    };

    const handleOpenMenus = () => {

        history.push('/SensorList')
    };


    const handleClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopup(false);
    };

    const handleClosePopupss = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopupss(false);
    };

    const handleSensorClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopups(false);
    };

    const QrCodeScannerComponent = ({ onClose }) => {
        // Your QR code scanning logic goes here
        // For demonstration purposes, a placeholder QR code scanner icon is used
        const [qrCodeValue, setQrCodeValue] = useState('');
        const [jsonData, setJsonData] = useState(null);
        useEffect(() => {
            // Fetch JSON data
            fetch('/deviceDetails.json')
                .then(response => response.json())
                .then(data => setJsonData(data))
                .catch(error => console.error('Error fetching data:', error));
        }, []);



        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: 'center', marginRight: '50px' }}>
                    {/* <Icon
                icon={"mdi:qrcode-scan"}
                size={350}
                style={{ color: '#2196F3', width: '200px', height: '200px', marginBottom: '20px' }}
              /> */}
                    {jsonData && <QRCode value={JSON.stringify(jsonData)} size={250} />}
                </div>

                <div style={{ textAlign: 'left', marginBottom: '140px' }}>
                    <Typography variant="h4" mt={2} mb={3} sx={{ marginBottom: '4rem' }} >
                        Scan QR Code
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                        1. Open iBsm App on your Device
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                        2. Point your Device to  QR code in the Screen
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                        3. Scan Qr code to Register the Device for  device registration



                    </Typography>

                </div>
            </div>
        );
    };

    useEffect(() => {
        ApiCalls.getdetails("listallsensors")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor !== undefined) {
                    setSensor(response.data.sensor);
                }
            })
            .catch((error) => {
                console.error("Error fetching device details:", error);
            });
    }, []);


    const loadCompanyList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        setLoading(true);
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/getCompanyDetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.companies != undefined) {
                    setCompany(response.data.companies);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCompany([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setCompany([]);
            })
    }


    useEffect(() => {
        var userId = secureLocalStorage.getItem("userid");
        ApiCalls.getCustomFieldList(userId)
            .then((response) => {
                if (response.data && response.data) {
                    // Filter items with menuText: "Manage Company"
                    const companyName = secureLocalStorage.getItem('CompanyName');
                    const filteredList = response.data.filter(item => item.menuText === `Manage ${companyName}`);
                    console.log(filteredList)
                    // Check if filteredList has items before setting it
                    if (filteredList.length > 0) {
                        setCustomFieldList(filteredList);
                    } else if (filteredList.length === 0) {
                        setCustomFieldList([])
                    }
                }
            })
    }, [])






    useEffect(() => {
        loadCompanyList();
    }, [])

    const filteredCompanies = company.filter((companies) =>
        companies.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - company.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleDeleteCompany = (companyId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var tenantId = secureLocalStorage.getItem("tenantId");

                    ApiCalls.delete("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/deleteCompany")
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deleted Successfully!", {
                                    icon: "success",
                                });
                                loadCompanyList();
                            }
                        });
                }
            });
    }

    const handleEditCompany = (companyId) => {
        secureLocalStorage.setItem("companyIdToEdit", companyId);
        setRedirectToEdit(true);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editcompany'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/addcustomer'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>
                        {/* 
                        <StyledBreadcrumb label={"Manage " + secureLocalStorage.getItem("CompanyName")} /> */}
                        <StyledBreadcrumb label="DeviceList" />
                    </Breadcrumbs>

                    {/* <Button variant="contained" onClick={() => setRedirectToForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New {secureLocalStorage.getItem("CompanyName")}
                    </Button> */}
                </Stack>

                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {/* {secureLocalStorage.getItem("CompanyName")} List */}
                            Device List
                        </Typography>}
                        action={
                            <TextField
                                // label={"Search " + secureLocalStorage.getItem("CompanyName")}
                                label="Search Device"
                            // value={searchTerm}
                            // onChange={(e) => setSearchTerm(e.target.value)}

                            />
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <center>
                                <CircularProgress />
                            </center>
                        ) : filteredCompanies.length > 0 ? (
                            <Grid container spacing={3}>
                                {filteredCompanies.map((company) => (
                                    <Grid item xs={12} sm={6} md={4} key={company.companyuuid}>
                                        <Card
                                            sx={{
                                                minWidth: 300,
                                                boxShadow: 3,
                                                backgroundColor: "#F4F6F8",
                                                '&:hover': {
                                                    border: '2px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                }
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: blue[300],
                                                            width: 150, // Adjust the width as needed
                                                            height: 150, // Adjust the height as needed
                                                            margin: 'auto',
                                                            marginLeft: '80px',// Center the avatar
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 0,
                                                        }}
                                                        aria-label="recipe"
                                                    >
                                                        <DevicesOtherIcon sx={{ fontSize: 48 }} />
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton onClick={(e) => handleOpenMenus()} onMouseDown={handleMouseDown} sx={{ float: "right" }}>
                                                        <Iconify icon={'mi:options-horizontal'} />
                                                    </IconButton>
                                                }
                                            />
                                            <CardContent>
                                                <Box sx={{ mt: 2, mb: 2 }}>
                                                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                        Device Name
                                                    </Typography>

                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        AndroidId
                                                    </Typography>

                                                </Box>

                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        OS version
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={handleScanButtonClick} >Authenticate</Button>
                                                <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={handlesenseButtonClick} >Sensor</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <center>No {secureLocalStorage.getItem("CompanyName")} found</center>
                        )}
                    </CardContent>

                </Card>
                {/* Popup Dialog */}
                <Dialog
                    open={openPopup}
                    onClose={handleClosePopup}
                    maxWidth="md"
                    fullWidth
                    sx={{ width: '90%' }}
                >
                    <DialogContent>
                        <QrCodeScannerComponent onClose={handleClosePopup} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog open={openPopups} onClose={handleSensorClosePopup}>
                    <CardHeader
                        title={"Add Sensors"}
                        action={
                            <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleSensorClosePopup}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {sensor.length > 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    maxHeight: 400,
                                    overflowY: 'scroll',
                                }}
                            >
                                {sensor.map((sensor) => (
                                    <FormControlLabel
                                        key={sensor.sensoruuId}
                                        control={
                                            <Checkbox
                                                checked={selectedSensorstoAssign.some(
                                                    (s) => s.id === sensor.sensoruuId && s.name === sensor.sensormacid
                                                )}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    const sensorId = sensor.sensoruuId;
                                                    const sensorName = sensor.sensormacid;
                                                    console.log(checked, sensorId, sensorName)

                                                    if (checked) {
                                                        setSelectedSensorstoAssign([
                                                            ...selectedSensorstoAssign,
                                                            { id: sensorId, name: sensorName },
                                                        ]);
                                                    } else {
                                                        setSelectedSensorstoAssign((prevState) =>
                                                            prevState.filter(
                                                                (d) => d.id !== sensorId || d.name !== sensorName
                                                            )
                                                        );
                                                    }
                                                    // Create a copy of the existing selected sensors

                                                    // Update the state with the modified array

                                                }}
                                                value={sensor.sensoruuId}
                                            />
                                        }
                                        label={
                                            <>
                                                <CardHeader
                                                    avatar={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Avatar
                                                                variant='rounded'
                                                                alt={sensor.sensormacid}
                                                                src={sensor.sensorImg}
                                                                sx={{ width: 90, height: 90, objectFit: 'contain', bgcolor: blue[900] }}
                                                            >
                                                                <EdgesensorHighTwoToneIcon sx={{ fontSize: 48 }} />
                                                            </Avatar>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', ml: 2 }}>
                                                                <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                    <Typography variant='h6' sx={{ color: 'text.primary' }}>
                                                                        {sensor.sensormacid}
                                                                    </Typography>
                                                                    <Typography variant='subtitle2' sx={{ color: 'text.secondary', ml: 2 }}>
                                                                        {sensor.sensorType}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Box sx={{
                                alignItems: 'center' // Center the content vertically
                            }}>
                                <div style={{ margin: 'auto', textAlign: 'center' }}>
                                    No {secureLocalStorage.getItem('SensorName')} Found
                                </div>
                            </Box>
                        )}
                    </CardContent>

                    <CardActions>
                        <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleSensorClosePopup} fullWidth >Cancel</Button>
                        <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAssignDevicetoPatient} fullWidth >Submit</Button>
                    </CardActions>
                </Dialog>



            </Container>
        </div >
    )
}

export default DeviceList
