import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

//@mui-components
import { AppBar, Avatar, Box, Drawer, IconButton, Link, MenuItem, Popover, Stack, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";

//components
import Iconify from '../../components/iconify';
import Logo from "../../components/logo";
import NavSection from "../../components/nav-section";
import Scrollbar from "../../components/scrollbar";

//utils
import { bgBlur } from "../../utils/cssStyles";
import AccountPopover from './AccountPopover';

//hooks
import useResponsive from '../../hooks/useResponsive';

//services
import ApiCalls from '../../services/try';

//externals
import secureLocalStorage from 'react-secure-storage';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 70;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const StyledHeadRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.grey[200] }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

const Preview = ({ onCompanySelect }) => {

  const [account, setAccount] = useState([]);
  const [navMenuConfig, setNavMenuConfig] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);


  const [openNav, setOpenNav] = useState(false);
  const [showCompanyFilter, setShowCompanyFilter] = useState(false);

  const [accountName, setAccountName] = useState("");
  const [roleName, setRoleName] = useState("");
  const { pathname } = useLocation();

  const [openCompanyFilter, setOpenCompanyFilter] = useState(null);

  // const userData = useSelector((state) => state.userData);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    let roleId = secureLocalStorage.getItem("roleId");
    let roleName = secureLocalStorage.getItem("rolename");

    if (roleId == 3) {
      setRoleName(secureLocalStorage.getItem("TenantName"));
    } else if (roleId === "4") {
      setRoleName(secureLocalStorage.getItem("CompanyName"));
    } else if (roleId == 6) {
      setRoleName(secureLocalStorage.getItem("PhysicianName"));
    } else if (roleId === "8") {
      setRoleName(secureLocalStorage.getItem("PatientName"));
    }

    let Id = secureLocalStorage.getItem("UUID");
    let userId = secureLocalStorage.getItem("tenantMailId");

    // let userId = userData.adminUser;

    ApiCalls.getdetails(+ roleId + "/" + Id + "/getdettt")
      .then((response) => {
        setAccount(response.data.userSchemas[0]);
        if (roleId == 4) {
          setAccountName(response.data.userSchemas[0].firstName);
        } else {
          setAccountName(response.data.userSchemas[0].firstName + " " + response.data.userSchemas[0].lastName);
        }
      });
    getRoleBasedMenu(userId, roleId)
    setDropdownSettings(roleName);
  }, []);

  const setDropdownSettings = (roleName) => {
    if (roleName === "Tenant") {
      setShowCompanyFilter(true)
      let userId = secureLocalStorage.getItem("UUID");
      getCompanyDetails(userId);
    } else {
      setShowCompanyFilter(false)
    }
  }

  const getCompanyDetails = (userId) => {
    ApiCalls.getdetails("organizations/0/tenants/" + userId + "/getCompanyNames")
      .then((response) => {
        if (response.data.companies != undefined) {
          const options = response.data.companies.map(d => ({
            "value": d.companyuuid,
            "label": d.name
          }))
          setCompany(options);
          setSelectedCompany(options[0])
        } else {
          const options = {
            "value": "0",
            "label": "None"
          }
          setCompany(options);
          selectedCompany(options[0])
        }
      })
  }

  const getRoleBasedMenu = (user, roleId) => {
    ApiCalls.getRoleBasedMenu(user, roleId)
      .then(function (response) {
        console.log(response.data);
        const menu = response.data;
        setNavMenuConfig(menu)
        const menuString = JSON.stringify(menu);
        secureLocalStorage.setItem("menu", menuString);
        secureLocalStorage.setItem("ismenuloaded", true);

      })
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const onCloseNav = () => {
    setOpenNav(false);
  }

  const handleOpenCompanyFilters = (e) => {
    setOpenCompanyFilter(e.currentTarget);
  }

  const handleCloseCompanyFilters = () => {
    setOpenCompanyFilter(null);
  }

  const handleCompanyFilterChange = (filter) => {
    setSelectedCompany(filter);
    secureLocalStorage.setItem("compId", filter.value);
    handleCloseCompanyFilters();

    // Call the callback function to notify the Dashboard component of the company selection.
    if (onCompanySelect) {
      onCompanySelect(filter.value);
    }
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.image} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {accountName == undefined ? secureLocalStorage.getItem("LoginUserName") : accountName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {roleName}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navMenuConfig} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <div>
      <StyledRoot>
        <StyledHeadRoot>
          <StyledToolbar>
            <IconButton
              onClick={() => setOpenNav(true)}
              onMouseDown={handleMouseDown}
              sx={{
                mr: 1,
                color: 'text.primary',
                display: { lg: 'none' },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>

            {/* <Searchbar /> */}

            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
            >
              {/* <Tooltip title={"Filter " + secureLocalStorage.getItem("CompanyName")}>
                <Button hidden={!showCompanyFilter} className='mr-5' color='primary' variant='contained' onClick={handleOpenCompanyFilters} endIcon={<Iconify icon="mi:filter" />}>
                  {selectedCompany.label}
                </Button>
              </Tooltip> */}
              {/* <LanguagePopover /> */}
              {/* <NotificationsPopover /> */}
              <AccountPopover account={account} />
            </Stack>

            <Popover
              open={Boolean(openCompanyFilter)}
              anchorEl={openCompanyFilter}
              onClose={handleCloseCompanyFilters}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  mt: 1.5,
                  ml: 0.75,
                  width: 180,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <Stack spacing={0.75}>
                {company.map((option) => (
                  <MenuItem key={option.value} selected={option.value === selectedCompany.value} onClick={() => handleCompanyFilterChange(option)} >
                    {option.label}
                  </MenuItem>
                ))}
              </Stack>
            </Popover>

          </StyledToolbar>
        </StyledHeadRoot>
      </StyledRoot>

      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    </div>
  )
}

export default Preview