import React from 'react';

//components
import Preview from '../../layout/Preview';

const Settings = () => {
  return (
    <div>
      <Preview />
    </div>
  )
}

export default Settings
