import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Home } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------



const EditPhysician = () => {

    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');

    const [companies, setcompanies] = useState([]);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [hover, setHover] = useState(false);

    const loadCompanyList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies")
            .then((response) => {
                console.log("Company Details");
                console.log("***********************************");
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                console.log(options);
                setcompanies(options)
            })
    }

    const loadPhysicianDetailsById = (physicianId) => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
        } else if (rolename === "Company") {
            tenantId = "0";
            companyId = secureLocalStorage.getItem("UUID");
        } else if (rolename === "Physician") {
            tenantId = "0";
            companyId = "0";
        }
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians/" + physicianId + "/getphysician")
            .then((response) => {
                console.log(response.data.physicians[0]);
                const data = response.data.physicians[0];
                console.log(data.dob)
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setSelectedCompany(data.companyId);
                setEmail(data.email);
                setImage(data.image);
                setGender(data.gender);
                setDob(data.dob ? data.dob.split(' ')[0] : '');
                setPhone(data.phone);
                setStreet(data.address.street);
                setCity(data.address.city);
                setState(data.address.state);
                setPostalCode(data.address.postalCode);
                setCountry(data.address.country);
            })
    }

    useEffect(() => {
        const physicianId = secureLocalStorage.getItem("physicianIdToEdit");
        console.log(physicianId)

        loadCompanyList();
        loadPhysicianDetailsById(physicianId);

    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                case 'email':
                    setEmailError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    break;
                case 'dob':
                    setDobError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case 'dob':
                const selectedDate = new Date(event.target.value);
                const today = new Date();
                const minAgeDate = new Date(today.getFullYear() - 20, today.getMonth(), today.getDate());
                if (selectedDate >= today) {
                    setDobError(true);
                } else if (selectedDate > minAgeDate) {
                    setDobError(true);
                } else {
                    setDobError(false);
                }
                setDob(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
    };

    const maxDate = formatDate(new Date());

    const handleUpdate = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!selectedCompany) {
            setCompanyError(true);
        } else if (!firstName) {
            setFirstNameError(true);
        } else if (!lastName) {
            setLastNameError(true);
        } else if (!email) {
            setEmailError(true);
        } else if (!emailRegex.test(email)) {
            setEmailError(true);
        } else if (!dob) {
            setDobError(true);
        } else if (!phone) {
            setPhoneError(true);
        } else if (!phoneRegex.test(phone)) {
            setPhoneError(true);
        } else {
            var tenantId = secureLocalStorage.getItem("tenantId");
            const physicianId = secureLocalStorage.getItem("physicianIdToEdit");
            var companyId = selectedCompany;

            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            }

            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                gender: gender,
                roleId: "6",
                phone: phone,
                address: address,
                image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
            }

            console.log("Final Data");
            console.log(data)
            ApiCalls.edit("organizations/0/tenants/" + tenantId + "/physicians/" + physicianId + "/updatephysicianbyId", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureLocalStorage.getItem("PhysicianName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    }
                })

        }

    }

    if (redirectToList) {
        return (
            <Redirect to={'/managetherapist'} />
        )
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managetherapist">
                            <Button variant="text" startIcon={<Icon icon={'fontisto:doctor'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("PhysicianName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureLocalStorage.getItem("PhysicianName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureLocalStorage.getItem("PhysicianName")}
                        </Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Avatar
                                                alt="Image"
                                                src={image}
                                                variant="circular"
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 150,
                                                    height: 150,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="ooui:user-avatar-outline" color="#4481eb" width="80" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>

                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="firstName"
                                                label={
                                                    <span>
                                                        First Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={firstName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={firstNameError}
                                                helperText={firstNameError && "First Name is required"}
                                            />
                                            <TextField
                                                name="lastName"
                                                label={
                                                    <span>
                                                        Last Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={lastName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={lastNameError}
                                                helperText={lastNameError && "Last Name is required"}
                                            />
                                            <TextField
                                                name="email"
                                                label={
                                                    <span>
                                                        Email{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={email}
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && "Email address is required"}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2} marginTop={3} justifyContent="space-between">
                                            <TextField
                                                id="date"
                                                name="dob"
                                                label={
                                                    <span>
                                                        DOB{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                type="date"
                                                value={dob}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: maxDate,
                                                }}
                                                error={dobError}
                                                helperText={dobError && "Enter a valid DOB"}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    label="Gender"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <MenuItem value="Female">Female</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                id='phone'
                                                name="phone"
                                                label={
                                                    <span>
                                                        Phone{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                value={phone}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && "Phone Number is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="street"
                                                label="Street Address"
                                                onChange={handleChange}
                                                value={street}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            />
                                            <TextField
                                                name="city"
                                                label="City"
                                                value={city}
                                                fullWidth
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                name="state"
                                                label="State"
                                                value={state}
                                                fullWidth
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="postalCode"
                                                label="Postal Code"
                                                value={postalCode}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            />
                                            <TextField
                                                name="country"
                                                label="Country"
                                                value={country}
                                                fullWidth
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <LoadingButton
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleUpdate}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                Update
                                            </LoadingButton>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default EditPhysician
