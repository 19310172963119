import React, { useEffect, useState } from "react";

//mui-components
import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  Paper,
  Tooltip
} from "@mui/material";

//services
import secureLocalStorage from "react-secure-storage";
import ApiCalls from "../../services/try";


const OrganisationStructure = ({ steps, activeStep, setActiveStep,isMedicalDomainsChecked, setIsMedicalDomainsChecked }) => {
 const [roles, setroles] = useState("customer");
  const [IsInEditMode, setIsInEditMode] = useState(false);
  const [role, setrole] = useState([]);
  const [val, setval] = useState(false);
  const [ne, setne] = useState(false);
  const [next, setnext] = useState(false);
  const [roleselected, setroleselected] = useState([]);
  const [rolescheck, setrolescheck] = useState([]);
  const [userid, setuserid] = useState("");
  const [id, setid] = useState("2");
  const [open, setopen] = useState(false);
  const [focus, setfocus] = useState(true);
  const [base, setbase] = useState(true);
  const [rolelist, setrolelist] = useState([]);
  const [doneediting, setdoneediting] = useState(false);

  useEffect(() => {
    console.log("before getrolelist api call...");
    var user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, "");
    console.log("User: ", user);
    setuserid(user);

    var a = [];
    console.log("goiing to call getrolelist api...");
    ApiCalls.getRoleList().then((response) => {
      console.log("Response: ", response.data);
      let list = response.data;
      ApiCalls.getSelectedRoleList(user).then((result) => {
        console.log("Result: ", result.data);
        setrolelist(result.data);
        if (result.data.length > 0) {
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < result.data.length; j++) {
              if (list[i].roleId === result.data[j].roleId) {
                list[i].roleName = result.data[j].roleName;
                list[i].checked = "true";
              }
            }
          }
          setrole(list);
          for (let i = 0; i < result.data.length; i++) {
            const st = {
              userId: user,
              roleId: result.data[i].roleId,
              customRoleName: result.data[i].roleName,
            };
            a.push(st);
          }
          setrolescheck(a);
        } else {
          for (let i = 0; i < list.length; i++) {
            list[i].checked = "true";
            const st = {
              userId: user,
              roleId: list[i].roleId,
              customRoleName: list[i].roleName,
            };
            a.push(st);
          }
          setrolescheck(a);
          setrole(list);
        }
      });
    });
  }, []);

  const editAll = () => {
    setfocus(false);
    console.log("look chek");
    setbase(false);
    console.log(base);
    alert(" Tap the Text to Edit");
    setdoneediting(true);
  };

  const doneEditing = () => {
    setfocus(true);
    console.log("look chek");
    setbase(true);
    setdoneediting(false);
  };

  const Rolecheck = (e, s) => {
    e.preventDefault();
    console.log(s);
    for (var i = 0; i < rolescheck.length; i++) {
      var dev = rolescheck[i].roleId;
      console.log("ckk" + rolescheck[i].roleId);
      if (dev == e.target.name) {
        console.log("s   : " + s);
        rolescheck[i].customRoleName = s;
      }
    }
  };

  const handleCheckboxChange = (e, index, s) => {
    console.log(index, s);
    console.log(e.target.name);
    console.log(e.target.value);
    console.log(e.target.checked);
    setid(e.target.name);

    setrolescheck(prevRolesCheck => {
      const updatedRolesCheck = [...prevRolesCheck];

      const roleIndex = updatedRolesCheck.findIndex(
        role => role.roleId === e.target.name
      );

      if (e.target.checked) {
        // If the role is selected, update the customRoleName in st
        updatedRolesCheck[roleIndex] = {
          ...updatedRolesCheck[roleIndex],
          customRoleName: s
        };
      } else {
        // If the role is deselected, remove it from rolescheck
        updatedRolesCheck.splice(roleIndex, 1);
      }

      return updatedRolesCheck;
    });
  };
  const a = [
    "Tenant is the first level of the hierarchical structure in iTouch",
    "Company is the next level of the hierarchical structure which comes after Tenant in iTouch",
    "Physician is the next level of the hierarchical structure which comes after Tenant and Company in iTouch",
    "Patient is the next level of the hierarchical structure which comes after Tenant ,Company and Physician in iTouch",
    "Various Actors,except Patient level users,can have the privileges to create new devices",
    60,
    70,
    80,
  ];

  let roleselect = role.map((rolename, index) => (
    <Paper sx={{
      backgroundColor: "#F4F6F8",
      boxShadow: 5,
      my: 1,
      height: 100,
      maxWidth: 500,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      px: 3,
      py: 3
    }}>
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            value={rolename.roleName}
            name={rolename.roleId}
            onChange={(e) =>
              handleCheckboxChange(e, index, rolename.roleName)
            }
            defaultChecked={rolename.checked}
          />}
        label={
          <InputBase
            readOnly={focus}
            name={rolename.roleId}
            sx={base ? {
              color: "#090A0A"
            } : {
              color: "#1976D2",
              bordercolor: "blue",
              border: "2px solid #38A3A5",
              borderRadius: "5px",
              width: "200px",
              height: "50px",
              px: 2
            }}
            autoFocus={base}
            defaultValue={rolename.roleName}
            onChange={(e) => {
              rolename.roleName = e.target.value;
              Rolecheck(e, rolename.roleName);
            }}
          />
        }
      />
      <Tooltip
        arrow
        placement="right"
        disableFocusListener
        interactive
        title={a[index]}
      >
        <Info />
      </Tooltip>
    </Paper>
  ))

  const forwardSubmit = () => {
    console.log("going to submit...")
    var roleselect = [];
    if (rolescheck.length == 0) {
    }
    for (var i = 0; i < rolescheck.length; i++) {
      roleselect.push(rolescheck[i]);
    }

    console.log("roleselect");
    console.log(roleselect);

    ApiCalls.insertSelectedRoles(roleselect).then((response) => {
      console.log("Response: " + JSON.stringify(response.data));
      if (response.data.successMessage === "RoleName changed.") {
        secureLocalStorage.setItem("selectedroles", JSON.stringify(rolescheck));
        setnext(!next);
      }
    });
  };

  return (

    <div bgcolor="#E8F6EF"

    >
    
       
      {/* </Card> */}
      <FormControlLabel
          control={
            <Checkbox
            checked={isMedicalDomainsChecked} 
            onChange={() => {
              setIsMedicalDomainsChecked(!isMedicalDomainsChecked);
              console.log("Checkbox state: " + isMedicalDomainsChecked);
            }}
            />
          }
          label={
            <span
              style={{
                gap:'108px',
                fontFamily: "Lato, sans-serif",
                color: 'F5F5F5',
                fontSize: '14px',
                marginBottom: '10px', 
                
                // Adjust the font size as needed
              }}
            >
              Medical Domains
            </span>
          }
          style={{ backgroundColor: "#e8eaf6", width: '180px',height: '35px',borderRadius: "5px",marginLeft:'320px',marginTop:'1px',position:'fixed'}}
        />
          <div style={{
        color: 'F5F5F5',
        
       
      }}>
        {base ? (
          <Button
            style={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
              marginBottom: '20px',
             
            }}
            onClick={editAll}
            variant="contained"
          >
            Change Role Names
          </Button>
        ) : (
          <Button
            style={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
              marginBottom: '10px',
              
            }}
            onClick={doneEditing}
            variant="contained"
          >
            Done Editing
          </Button>
        )}
         <div style={{
        color: 'F5F5F5',
       
      }}>
 
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }} >
          {
            roleselect
          }
        </Box>

        <Button
          disabled={doneediting}
          variant="contained"
          color="primary"
          onClick={() => {
            setActiveStep(1);
            forwardSubmit();
          }}
        >
          Next
        </Button>
        </div>
        </div>
      </div>
      
  );
};

export default OrganisationStructure;