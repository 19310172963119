import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//bootstrap-components
import { MDBDataTable } from 'mdbreact';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

//----------------------------------------------------------------

const DeviceView = () => {

    const [device, setDevice] = useState([]);
    const [assignedPatients, setAssignedPatients] = useState([]);
    const [deviceParameters, setDeviceParameters] = useState([]);
    const [deviceMeasurements, setDeviceMeasurements] = useState([]);

    const [loading, setLoading] = useState(false);

    const [patientInfo, setPatientInfo] = useState('false');

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const loadDeviceDetailsById = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/viewdevice")
            .then((response) => {
                console.log(response.data);
                if (response.data != undefined) {
                    setDevice(response.data);
                } else {
                    setDevice([]);
                }
            })
    }

    const loadDeviceAsignedPatients = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/getpatientsassigned")
            .then((response) => {
                console.log(response.data);
                if (response.data.patient != undefined) {
                    setAssignedPatients(response.data.patient);
                } else {
                    setAssignedPatients([]);
                }
            })
    }

    const loadDeviceParameters = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/getparameters")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameters != undefined) {
                    setDeviceParameters(response.data.parameters);
                } else {
                    setDeviceParameters([]);
                }
            })
    }

    const loadDeviceMeasurements = (deviceId) => {
        setLoading(true);
        ApiCalls.getdetails("device/" + deviceId + "/getparametermeasurement")
            .then((response) => {
                console.log(response.data);
                if (response.data.measure != undefined) {
                    setDeviceMeasurements(response.data.measure);
                    setLoading(false);
                } else {
                    setDeviceMeasurements([]);
                    setLoading(false);
                }
            })
    }

    useEffect(() => {

        const device = JSON.parse(secureLocalStorage.getItem("deviceIdtoView"));
        loadDeviceDetailsById(device.decmacid);
        var roleId = secureLocalStorage.getItem("roleId");

        ApiCalls.getdetails("roleId/" + roleId + "/getpatientprivilegedetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientInfo;
                    console.log("PatientInfo: " + patientInfo);
                    setPatientInfo(patientInfo);
                    if (patientInfo === "true") {
                        loadDeviceAsignedPatients(device.decmacid)
                    }
                }
            })
        loadDeviceParameters(device.decmacid)
        loadDeviceMeasurements(device.decmacid);
    }, [])

    let userFeed = deviceMeasurements.map(function (measures) {
        var utc = measures.measTimeStamp;
        var date = moment.utc(Date.parse(utc)).local().format("MMM-DD hh:mm A");
        const row = {
            paramName: measures.paramName,
            meastime: date,
            value:
                measures.paramName === "Panic" ? (
                    <p style={{ color: "red", fontFamily: "calibri", fontWeight: "bold" }}>
                        {" "}{measures.paramName + " Occurred"}
                    </p>
                ) : measures.paramName === "Motion" ? (
                    measures.paramName + " Detected"
                ) : (
                    measures.measurevalues
                ),
        };
        return row;
    });


    const data = {
        columns: [
            {
                label: 'Parameter',
                field: 'paramName',
                sort: 'asc'
            },
            {
                label: 'Measured @',
                field: 'meastime',
                sort: 'asc'
            },
            {
                label: 'Reading',
                field: 'value',
                sort: 'asc'
            }
        ],
        rows: userFeed,
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={secureLocalStorage.getItem("DeviceName") + " View"} />
                    </Breadcrumbs>

                </Stack>

                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureLocalStorage.getItem("DeviceName")} View
                        </Typography>}
                    />
                    <CardContent >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} >
                                <Card sx={{
                                    boxShadow: 3,
                                    px: 2,
                                    py: 3,
                                    minHeight: 200,
                                    backgroundColor: "#F4F6F8",
                                }}>

                                    <CardHeader
                                        avatar={<Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Avatar
                                                variant='rounded'
                                                alt={device.devicename}
                                                src={device.deviceImage}
                                                sx={{ width: 120, height: 120, objectFit: "contain" }} />
                                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Device Name
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        {device.devicename}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Device Macid
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        {device.devmacid}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                        Model Name
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        {device.modelName == undefined
                                                            ? " "
                                                            : device.modelName}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        } />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} >
                                <Card sx={{
                                    boxShadow: 3,
                                }}>
                                    <CardHeader title="Parameters" />
                                    <CardContent sx={{
                                        minHeight: 200,
                                        maxHeight: 200,
                                        overflowY: "scroll",
                                        py: 1,
                                        px: 5,
                                    }}>
                                        {
                                            deviceParameters.length > 0 ?
                                                <>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "wrap",
                                                        justifyContent: "space-between",
                                                    }}>
                                                        {deviceParameters.map((parameter) => (
                                                            <ListItemButton key={parameter.id} sx={{ width: "50%" }}>
                                                                <ListItemIcon>
                                                                    <Icon icon={'tabler:layout-list'} width="25" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={parameter.name} />
                                                            </ListItemButton>
                                                        ))}
                                                    </Box>
                                                </>
                                                : <center>
                                                    No Parameter Found
                                                </center>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} >
                                <Card sx={{
                                    boxShadow: 3,
                                    display: patientInfo === "false" ? "none" : ""
                                }}>
                                    <CardHeader title={secureLocalStorage.getItem("PatientName") + " List"} />
                                    <CardContent sx={{
                                        maxHeight: 300,
                                        overflowY: "scroll",
                                    }}>
                                        <Grid container spacing={2}>
                                            {
                                                assignedPatients.length > 0 ?
                                                    assignedPatients.map((patient) => (
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <Card sx={{
                                                                position: 'relative',
                                                                boxShadow: 3,
                                                                backgroundColor: "#F4F6F8",
                                                            }}>
                                                                <CardHeader
                                                                    avatar={
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                alt={patient.firstName + " " + patient.lastName}
                                                                                src={patient.image}
                                                                                sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                        {patient.firstName + " " + patient.lastName}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        <Icon icon={'gridicons:mail'} /> {patient.email}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        <Icon icon={'ic:baseline-phone'} /> {patient.phone}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    } />
                                                            </Card>
                                                        </Grid>
                                                    )) :
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                {"No " + secureLocalStorage.getItem("PatientName") + " Assigned"}
                                                            </Box>
                                                        } />
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} >
                                <Card sx={{
                                    boxShadow: 3
                                }}>
                                    <CardHeader title="Measurements" />
                                    <CardContent>
                                        <MDBDataTable
                                            theadColor='primary-color'
                                            borderless
                                            entriesOptions={[5, 10, 20, 50, 100]}
                                            fullPagination
                                            entries={5}
                                            tag
                                            dark={false}
                                            noBottomColumns
                                            responsive
                                            data={data}
                                            striped
                                            btn={true}
                                            sortable={false}
                                            noRecordsFoundLabel={<center>No Measurements Found</center>}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </div >
    )
}
export default DeviceView
