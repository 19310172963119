import React, { useEffect, useState } from 'react';
// import Apicalls from '../../services/try';
import { Dialog } from '@mui/material';
import downarrow from "../../assets/images/downarrow.png";
import uparrow from "../../assets/images/uparrow.png";
import AlertBox from './AlertBox';

function DialogBox({ index, openDialog, setSlide, slide, setOpenDialog, setIndex }) {

    // const [GraphTypeSelected, setGraphTypeSelected] = useState(0);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState('');
    console.log("openDialog", openDialog);
    console.log("slide in Dialog", slide);

    useEffect(() => {
        // setGraphTypeSelected(index);
        setIndex(index);
    }, [index]);

    const limit = [
        { min: 30, max: 295 },
        { min: 70, max: 100 },
        { min: 8, max: 35 }
    ];
    const slideInfo = [
        {
            color: '#3BE041',
            range: {
                min: slide[0].min, max: slide[0].max
            },
            tag: 'Ecg',
            value: 'HR',
            unit: 'bpm'

        },
        {
            color: '#009FE3',
            range: {
                min: slide[1].min, max: slide[1].max
            },
            tag: 'SpO₂',
            value: 'SPO2',
            unit: '%'
        },
        {
            color: '#F2B809',
            range: {
                min: slide[2].min, max: slide[2].max
            },
            tag: 'RR',
            value: 'RR',
            unit: 'rpm'
        },
    ];

    // const handleCloseAddGroup = () => {
    //     console.log("handleCloseAddGroup");
    //     setOpenDialog(false);
    // }

    // const handleminChange = (e) => {
    //     let newValue = e.target.value;
    //     if (newValue === '') {
    //         let oldobject = slide;
    //         oldobject[GraphTypeSelected].min = '';
    //         setSlide(oldobject);
    //     } else {
    //         newValue = parseInt(newValue, 10);
    //         console.log("handleChange", newValue);
    //         // if (newValue >= limit[GraphTypeSelected].min && newValue <= limit[GraphTypeSelected].max) {
    //         console.log("handleChange", "IF condition");
    //         let oldobject = slide;
    //         oldobject[GraphTypeSelected].min = newValue;
    //         setSlide(oldobject);
    //         console.log("handleChange", oldobject);
    //         // }
    //     }
    // };

    // const handlemaxChange = (e) => {
    //     let newValue = e.target.value;
    //     if (newValue === '') {
    //         let oldobject = slide;
    //         oldobject[GraphTypeSelected].max = '';
    //         setSlide(oldobject);
    //     } else {
    //         newValue = parseInt(newValue, 10);
    //         console.log("handleChange", newValue);
    //         // if (!isNaN(newValue) && newValue >= limit[GraphTypeSelected].min && newValue <= limit[GraphTypeSelected].max) {
    //         console.log("handleChange", "IF condition");
    //         let oldobject = slide;
    //         oldobject[GraphTypeSelected].max = newValue;
    //         setSlide(oldobject);
    //         console.log("handleChange", oldobject);
    //         // }
    //     }
    // };
    // const handleminChange = (e) => {
    //     let newValue = e.target.value;
    //     newValue = parseInt(newValue, 10);
    //     if (!isNaN(newValue)) {
    //         setSlide(prevSlide => ({
    //             ...prevSlide,
    //             [index]: {
    //                 ...prevSlide[index],
    //                 min: newValue
    //             }
    //         }));
    //     }
    // };

    // const handlemaxChange = (e) => {
    //     let newValue = e.target.value;
    //     newValue = parseInt(newValue, 10);
    //     if (!isNaN(newValue)) {
    //         setSlide(prevSlide => ({
    //             ...prevSlide,
    //             [index]: {
    //                 ...prevSlide[index],
    //                 max: newValue
    //             }
    //         }));
    //     }
    // };
    const handleminChange = (e) => {
        const newValue = e === '' ? '' : parseInt(e, 10);
        if (newValue === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= 295)) {
            setSlide(prevSlide => prevSlide.map((item, idx) =>
                idx === index ? { ...item, min: newValue } : item
            ));
        }
    };

    const handlemaxChange = (e) => {
        const newValue = e === '' ? '' : parseInt(e.target.value, 10);
        if (newValue === '' || (!isNaN(newValue) && newValue >= 0 && newValue <= 295)) {
            setSlide(prevSlide => prevSlide.map((item, idx) =>
                idx === index ? { ...item, max: newValue } : item
            ));
        }
    };


    // const handleChange = (e, type) => {
    //     console.log("handleChange", type);
    //     let newValue = e.target.value;
    //     if (newValue === '') {
    //         if (type = 'min') {
    //             let oldobject = slide;
    //             oldobject[index].min = '';
    //             setSlide(oldobject);
    //         }
    //         else {
    //             let oldobject = slide;
    //             oldobject[index].max = '';
    //             setSlide(oldobject);
    //         }
    //     } else {
    //         newValue = parseInt(newValue, 10);
    //         console.log("handleChange", newValue);
    //         // if (!isNaN(newValue) && newValue >= limit[GraphTypeSelected].min && newValue <= limit[GraphTypeSelected].max) {
    //         console.log("handleChange", "IF condition");
    //         if (type = 'min') {
    //             let oldobject = slide;
    //             oldobject[index].min = newValue;
    //             setSlide(oldobject);
    //             console.log("handleChange", oldobject);
    //         }
    //         else {
    //             let oldobject = slide;
    //             oldobject[index].max = newValue;
    //             setSlide(oldobject);
    //             console.log("handleChange", oldobject);
    //         }
    //         // }
    //     }
    // };

    // const increment = (type) => {
    //     setSlide(prevSlide => {
    //         const newValue = type === 'min' ? prevSlide[index].min + 1 : prevSlide[index].max + 1;
    //         const newValueLimited = newValue > limit[index].max ? limit[index].max : newValue;
    //         return {
    //             ...prevSlide,
    //             [index]: {
    //                 ...prevSlide[index],
    //                 [type]: newValueLimited
    //             }
    //         };
    //     });
    // };


    // const decrement = (type) => {
    //     setSlide(prevSlide => {
    //         const newValue = type === 'min' ? prevSlide[index].min - 1 : prevSlide[index].max - 1;
    //         const newValueLimited = newValue < limit[index].min ? limit[index].min : newValue;
    //         return {
    //             ...prevSlide,
    //             [index]: {
    //                 ...prevSlide[index],
    //                 [type]: newValueLimited
    //             }
    //         };
    //     });
    // };


    const increment = (type) => {
        setSlide(prevSlide => prevSlide.map((item, idx) => {
            if (idx !== index) return item;
            const newValue = type === 'min' ? item.min + 1 : item.max + 1;
            const limitedValue = newValue > limit[idx].max ? limit[idx].max : newValue;
            return { ...item, [type]: limitedValue };
        }));
    };

    const decrement = (type) => {
        setSlide(prevSlide => prevSlide.map((item, idx) => {
            if (idx !== index) return item;
            const newValue = type === 'min' ? item.min - 1 : item.max - 1;
            const limitedValue = newValue < limit[idx].min ? limit[idx].min : newValue;
            return { ...item, [type]: limitedValue };
        }));
    };


    const savetoDB = () => {
        slideInfo.map((item, index) => {
            console.log("Save to database", slideInfo[index].value);
            // setOpenDialog(false);
            if (slide[index].min < limit[index].min || slide[index].min > limit[index].max) {
                // alert(`${slideInfo[index].value} Values Entered not within Range`);
                setMessage(`${slideInfo[index].value} Values Entered not within Range`);
                setOpenAlert(true);
                let oldobject = slide;
                oldobject[index].min = limit[index].min;
                setSlide(oldobject);
                setOpenDialog(true);
            } else if (slide[index].max < limit[index].min || slide[index].max > limit[index].max) {
                // alert(`${slideInfo[index].value} Values Entered not within Range`);
                setMessage(`${slideInfo[index].value} Values Entered not within Range`);
                setOpenAlert(true);
                let oldobject = slide;
                oldobject[index].max = limit[index].max;
                setSlide(oldobject);
                setOpenDialog(true);
            }
            else {
                if (slide[0].min < slide[0].max && slide[1].min < slide[1].max && slide[2].min < slide[2].max) {
                    console.log("IF condition")
                    const thresholddata = {
                        "min": slide[index].min,
                        "max": slide[index].max
                    }
                    // savetoDatabase(thresholddata, index);
                    setOpenDialog(false);
                    let datatosend = { thresholddata, "index": index }
                    // sendMessageToReactNative();
                    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(datatosend));
                    } else {
                        console.error("ReactNativeWebView is not defined");
                    }
                }
                else {
                    // // alert(`Min value of ${slideInfo[index].value} cannot be greater than or equal to Max value`);
                    // setMessage(`Min value of ${slideInfo[index].value} cannot be greater than or equal to Max value`);
                    if (index == 0 && slide[0].min >= slide[0].max)
                        setMessage(`Min value of HR cannot be greater than or equal to Max value`);
                    else if (index == 1 && slide[1].min >= slide[1].max)
                        setMessage(`Min value of SpO₂ cannot be greater than or equal to Max value`);
                    else if (index == 2 && slide[2].min >= slide[2].max)
                        setMessage(`Min value of RR cannot be greater than or equal to Max value`);
                    setOpenAlert(true);
                    setOpenDialog(true);
                }
            }
        });
    }

    // const savetoDatabase = async (thresholddata, index) => {

    //     console.log("savetoDatabase", thresholddata, index);
    //     try {
    //         // setLoading(true);
    //         // const response = await postApiData(`PatientId/${patientId}/paramname/${get(sliderInfo[index], "value")}`, thresholddata);
    //         const response = await Apicalls.storethreshold(`https://itouch.iorbit.health:8443/api/v1/PatientId/e5434736-faff-11ee-a1ca-f3533c571579/paramname/${slideInfo[index].value}`, thresholddata, decodedtoken);
    //         console.log("response", response);
    //         if (response.status == 200) {
    //             console.log('Patient Threshold saving successful:', response);
    //             return response;
    //         }
    //         else {
    //             console.log('responce', response);
    //             // setLoading(false)
    //             alert(
    //                 "ProblemABCD saving Threshold data",
    //                 "Please try again !!!",
    //                 [
    //                     { text: "OK" }
    //                 ]
    //             );
    //         }
    //     } catch (error) {
    //         console.error('Error in Patient Threshold saving:', error);
    //         // setLoading(false)
    //         alert(
    //             "ProblemXYZ saving Threshold data",
    //             "Please try again !!!",
    //             [
    //                 { text: "OK" }
    //             ]
    //         );
    //         throw error;
    //     }
    // }

    return (
        <>
            {/* <Dialog open={openDialog} onClose={() => { setOpenDialog(false); savetoDB(); }}> */}
            <Dialog open={openDialog} onClose={() => { savetoDB(); }}>
                <div style={{ backgroundColor: '#080f18' }}>
                    <div style={{ display: 'flex', paddingTop: '20px' }}>
                        <button onClick={() => { setIndex(0) }} style={{ ...styles.button, backgroundColor: (index == 0 ? "rgba(39, 245, 41, 0.8)" : 'grey') }}>
                            HR
                        </button>
                        <button onClick={() => { setIndex(1) }} style={{ ...styles.button, backgroundColor: (index == 1 ? "rgb(75, 192, 192)" : 'grey') }}>
                            SPO2
                        </button>
                        <button onClick={() => { setIndex(2) }} style={{ ...styles.button, backgroundColor: (index == 2 ? "rgba(255, 247, 0, 0.8)" : 'grey') }}>
                            RR
                        </button>
                    </div>
                    <div style={styles.dialog}>
                        <div style={{ alignItems: 'center' }}>
                            <div style={{ textAlign: 'center', color: 'white' }}><p>Min Value</p></div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={() => decrement('min')} disabled={slide[index].min <= limit[index].min} style={{ ...styles.smallbutton, paddingTop: '1px' }}>
                                    <img src={downarrow} class="image" alt="downarrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                </button>
                                <input
                                    type="text"
                                    value={slide[index].min}
                                    onChange={(e) => handleminChange(e)}
                                    style={{ textAlign: 'center', width: '50px', backgroundColor: '#1D252E', color: 'white', borderColor: 'transparent', border: 'none', background: 'none', outline: 'none' }}
                                />
                                <button onClick={() => increment('min')} disabled={slide[index].min >= limit[index].max} style={{ ...styles.smallbutton, paddingBottom: '4px' }}>
                                    <img src={uparrow} class="image" alt="uparrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                </button>
                            </div>
                            <div style={{ textAlign: 'center', color: 'white', paddingTop: 10 }}><p>Max Value</p></div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={() => decrement('max')} disabled={slide[index].max <= limit[index].min} style={{ ...styles.smallbutton, paddingTop: '1px' }}>
                                    <img src={downarrow} class="image" alt="downarrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                </button>
                                <input
                                    type="text"
                                    value={slide[index].max}
                                    onChange={(e) => handlemaxChange(e)}
                                    style={{ textAlign: 'center', width: '50px', backgroundColor: '#1D252E', color: 'white', borderColor: 'transparent', border: 'none', background: 'none', outline: 'none' }}
                                />
                                <button onClick={() => increment('max')} disabled={slide[index].max >= limit[index].max} style={{ ...styles.smallbutton, paddingBottom: '4px' }}>
                                    <img src={uparrow} class="image" alt="uparrow" width="25px" height="25px" style={{ objectFit: 'contain' }} />
                                </button>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '25px' }}>
                                <button onClick={savetoDB} style={{ border: 'none', background: 'none', outline: 'none', borderRadius: '12px', backgroundColor: "#009FE3", width: '100px', marginLeft: '20px' }}>
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog >
            <AlertBox openAlert={openAlert} message={message} setOpenAlert={setOpenAlert} />
        </>
    )
}

export default DialogBox;

const styles = {
    dialog: {
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        height: '300px', // Fixed height
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        borderRadius: '10px', border: 'none', background: 'none', marginLeft: '10px', width: '120px', outline: 'none'
    },
    smallbutton: {
        border: 'none', background: 'none', outline: 'none', margin: '2px',
        backgroundColor: '#009FE3', marginLeft: '10px', marginRight: '10px', height: '26px', width: '26px',
        paddingLeft: '4px', paddingRight: '4px',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center'
    }
};