import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './index.css';

function ChartVitalDisplay({ index, colour, vitalvalue, decodedpatient, slideInfo, slide }) {

    const [borderColor, setBorderColor] = useState('transparent')
    useEffect(() => {
        console.log("vitalvalue", vitalvalue);
        setBorderColor(() => {
            if (vitalvalue) {
                console.log("vitalvalue", vitalvalue);
                if (vitalvalue == -100) {
                    return 'transparent'
                }
                else if (vitalvalue < slide.min || vitalvalue > slide.max) {
                    return 'red'
                } else {
                    return 'transparent'
                }
            }
        })
    }, [vitalvalue]);


    return (
        <div style={{ width: '80%', display: 'flex', border: `2px solid ${borderColor}`, padding: 0, margin: 0 }}>
            <div style={{ width: '30px' }}>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].tag}</p>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].range.min}</p>
                <p style={{ color: colour, fontSize: '1.5rem', textAlign: 'end' }}>{slideInfo[index].range.max}</p>
            </div>
            <div style={{ width: '114px' }}>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: colour, fontSize: '3.5rem' }}>{(vitalvalue > 0) ? vitalvalue : '--'}</p>
                </div>
            </div>
            <div style={{
                position: 'relative',
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
            }}>
                <div style={{
                    // position: 'absolute',
                    bottom: 0,
                    float: 'right',
                }} >
                    <p style={{ color: colour, fontSize: '1.5rem' }}>{slideInfo[index].unit}</p>
                </div>
            </div>
        </div >
    )
}

export default ChartVitalDisplay;

const styles = {
    dialog: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        height: '300px', // Fixed height
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    }
};

const LargeText = styled.p`
  font-size: 3rem;
`;
