import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Bed, Close, Delete, Edit, Home } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}

//-------------------------------------------------------------------------------

const ManageCMS = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState('');
    const [department, setDepartment] = useState('');
    const [cmsId, setCmsId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [cms, setCms] = useState([]);
    const [bedAssigned, setBedAssigned] = useState([]);
    const [bedList, setBedList] = useState([]);
    const [selectedCmstoView, setSelectedCmstoView] = useState([]);
    const [selectedBedToDeselect, setSelectedBedToDeselect] = useState([]);

    const [nameError, setNameError] = useState('');
    const [departmentError, setDepartmentError] = useState('');
    const [cmsIdError, setCmsIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [selectedBedstoAssign, setSelectedBedstoAssign] = useState('');
    const [selectedCmstoEdit, setSelectedCmstoEdit] = useState('');
    const [patientInfo, setPatientInfo] = useState('false');

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(null);
    const [openAddCms, setOpenAddCms] = useState(false);
    const [bedLoading, setBedLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [openAddMoreBed, setOpenAddMoreBed] = useState(false);
    const [loadingBedList, setLoadingBedList] = useState(false);
    const [openEditCms, setOpenEditCms] = useState(false);
    const [redirectToView, setRedirectToView] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [tabValue, setTabValue] = useState(0);

    const loadCmsList = () => {
        let tenantId = secureLocalStorage.getItem("tenantId");
        let companyId = secureLocalStorage.getItem("compId");
        let rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
        }

        ApiCalls.getdetails("tenant/" + tenantId + "/company/" + companyId + "/cmsall")
            .then((response) => {
                console.log(response.data);
                if (response.data.cms !== undefined) {
                    setCms(response.data.cms);
                    setLoading(false);
                } else {
                    setCms([]);
                    setLoading(false);
                }
            }).catch((err) => {
                console.log(err);
                setCms([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        setLoading(true);
        loadCmsList();

        var roleId = secureLocalStorage.getItem("roleId");

        ApiCalls.getdetails("roleId/" + roleId + "/getpatientprivilegedetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientWaveform;
                    console.log("PatientInfo: " + patientInfo);
                    setPatientInfo(patientInfo);
                }
            })


    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const filteredCms = cms.filter((cmsdata) =>
        cmsdata.cmsName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cmsdata.departmentName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const loadBedList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");

        ApiCalls.getdetails(
            "tenant/" + tenantId + "/company/" + companyId + "/bedstatus"
        ).then((response) => {
            console.log(response.data);
            if (response.data.beds != undefined) {
                setBedList(response.data.beds);
                setLoadingBedList(false);
            } else {
                setBedList([]);
                setLoadingBedList(false);
            }
        }).catch((error) => {
            console.log(error);
            setBedList([]);
            setLoadingBedList(false);
        })
    };

    const difference = bedList.filter(item => {
        return !bedAssigned.some(otherItem => {
            return item.bedId === otherItem.bedId;
        });
    });

    const handleOpenCMS = (cms) => {
        setSelectedCmstoView(cms);
        loadCmsDetailsById(cms.cmsId);
        loadBedList();
        setOpen(true);
    }

    const handleCloseCMS = (cms) => {
        setOpen(false);
        setSelectedCmstoView([]);
        setSelectedBedToDeselect([]);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenAddCms = () => {
        setOpenAddCms(true);
    }

    const handleCloseAddCms = () => {
        setOpenAddCms(false);
        loadCmsList();
    }

    const loadCmsDetailsById = (cmsId) => {
        setBedLoading(true);
        let tenantId = secureLocalStorage.getItem("tenantId");
        let companyId = secureLocalStorage.getItem("compId");

        ApiCalls.getdetails("bedall/tenant/" + tenantId + "/company/" + companyId + "/cms/" + cmsId)
            .then((response) => {
                console.log(response.data);
                const data = response.data.beds
                if (data !== undefined) {
                    setBedAssigned(data);
                    setBedLoading(false);
                }
                else {
                    setBedAssigned([]);
                    setBedLoading(false);
                }
            })
    }

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setNameError(true);
                    break;
                case 'department':
                    setDepartmentError(true);
                    break;
                case 'cmsId':
                    setCmsIdError(true);
                    break;
                case 'password':
                    setPasswordError(true);
                    break;
                case 'confirmPassword':
                    setConfirmPasswordError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'department':
                setDepartmentError(false);
                setDepartment(event.target.value);
                break;
            case 'cmsId':
                setCmsIdError(false);
                setCmsId(event.target.value);
                break;
            case 'password':
                setPasswordError(false);
                setPassword(event.target.value);
                break;
            case 'confirmPassword':
                setConfirmPasswordError(false);
                setConfirmPassword(event.target.value);
                break;
            default:
                break;
        }

        // Check if password and confirm password match
        if (event.target.name === 'confirmPassword') {
            if (event.target.value !== password) {
                setConfirmPasswordError(true);
            } else {
                setConfirmPasswordError(false);
            }
        }
    };

    const handleAddCMS = () => {
        if (!name) {
            setNameError(true);
        } else if (!department) {
            setDepartmentError(true);
        } else if (!cmsId) {
            setCmsIdError(true);
        } else if (!password) {
            setPasswordError(true);
        } else if (!confirmPassword) {
            setConfirmPasswordError(true);
        } else {
            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = secureLocalStorage.getItem("compId");

            var data = {
                cmsName: name,
                companyId: companyId,
                departmentName: department,
                cmsDevId: cmsId,
                cmsDevPassword: confirmPassword,
                tenantId: tenantId,
            }

            ApiCalls.register("createcms", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("CMS Successfully Registered", {
                            icon: "success",
                        });
                        handleCloseAddCms();
                    } else if (response.data.status.details === 'Already existing CmsDevId') {
                        swal("CMS Already Registered", {
                            icon: "info",
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleUpdateCMS = () => {
        if (!name) {
            setNameError(true);
        } else if (!department) {
            setDepartmentError(true);
        } else if (!cmsId) {
            setCmsIdError(true);
        } else {
            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = secureLocalStorage.getItem("compId");
            const cmsFormBody = {
                cmsName: name,
                companyId: companyId,
                tenantId: tenantId,
                departmentName: department,
                cmsDevId: cmsId,
            };
            ApiCalls.edit("updatecms/" + selectedCmstoEdit, cmsFormBody)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("CMS Successfully Updated", {
                            icon: "success",
                        });
                        handleCloseEditCms();
                    }
                })
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleDeleteCms = (cmsId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.delete("deletecms/" + cmsId)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Deleted Successfully!", {
                                icon: "success",
                            });
                            loadCmsList();
                        }
                    })
            }
        })
    }

    const handleOpenEditCms = (cmsId) => {
        setSelectedCmstoEdit(cmsId);
        ApiCalls.getdetails("getcms/" + cmsId)
            .then((response) => {
                console.log(response.data);
                if (response.data.cms != undefined) {
                    setName(response.data.cms[0].cmsName);
                    setDepartment(response.data.cms[0].departmentName);
                    setCmsId(response.data.cms[0].cmsDevId);
                }
            })
        setOpenEditCms(true);
    }

    const handleCloseEditCms = () => {
        loadCmsList();
        setOpenEditCms(false);
    }

    const handleOpenAddMoreBed = () => {
        setOpenAddMoreBed(true);
    }

    const handleCloseAddMoreBed = () => {
        setOpenAddMoreBed(false);
    }

    const handleAddMoreBed = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var data = {

        }

        ApiCalls.register(
            "assign/tenant/" +
            tenantId +
            "/company/" +
            companyId +
            "/cms/" +
            selectedCmstoView.cmsId +
            "/bed/" +
            selectedBedstoAssign,
            data
        ).then((response) => {
            console.log(response.data);
            if (response.data.status.message === "Success") {
                swal("Bed Successfully Assigned", {
                    icon: "success",
                });
                handleCloseAddMoreBed();
                loadCmsDetailsById(selectedCmstoView.cmsId);
            } else if (response.data.status.details === "Cms Already Assigned To The Bed") {
                swal("Bed Already Assigned to this CMS", {
                    icon: "info",
                });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleDeselectBed = () => {
        console.log(selectedCmstoView);
        console.log(selectedBedToDeselect);

        let a = [];
        let list = selectedBedToDeselect.length;
        console.log("list: " + list);
        for (var i = 0; i < list; i++) {
            var counter = selectedBedToDeselect[i];
            const bedId = counter.id;
            var s = { bedId: bedId };
            a.push(s);
        }

        var data = {
            beds: a,
        };

        swal({
            title: "Are you sure?",
            text: "Do you want to Deselect this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.edit("cms/" + selectedCmstoView.cmsId + "/deletebedassigned", data)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Bed Deselected!", {
                                icon: "success",
                            });
                            loadCmsDetailsById(selectedCmstoView.cmsId);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });

    }

    const handleCloseMenu = () => {
        setOpenView(null);
    };

    const handleClickView = () => {
        setRedirectToView(true);
    }

    if (redirectToView) {
        return (<Redirect to={'/cmsview'} />)
    }

    const handleOpenMenu = (event, cms) => {
        console.log("View Patient:", cms.cmsDevId);
        secureLocalStorage.setItem("CMSToView", cms.cmsDevId)
        secureLocalStorage.setItem("cmsName", cms.cmsName)
        setOpenView(event.currentTarget);
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Manage CMS" />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureLocalStorage.getItem("rolename") === "Company" ? "" : "none"
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAddCms}>
                        New CMS
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title="CMS List"
                        action={
                            <TextField
                                label="Search CMS"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredCms.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredCms.map((cms) => (
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Card
                                                key={cms.cmsId}
                                                sx={{
                                                    maxWidth: 200,
                                                    boxShadow: 3,
                                                    backgroundColor: "#F4F6F8",
                                                    '&:hover': {
                                                        border: '2px solid #D1E9FC',
                                                        boxShadow: 'none'
                                                    },
                                                }}>
                                                <CardHeader
                                                    sx={{
                                                        paddingBottom: 0,
                                                        marginBottom: 0,
                                                    }}
                                                    // action={
                                                    //     <IconButton onClick={(e) => handleOpenMenu(e, cms)} onMouseDown={handleMouseDown} sx={{ float: "right", display: patientInfo === "false" ? "none" : "" }}>
                                                    //         <Iconify icon={'typcn:th-menu'} />
                                                    //     </IconButton>
                                                    // }
                                                />
                                                <CardContent
                                                    sx={{
                                                        cursor: "pointer",
                                                        paddingTop: 0,
                                                        marginTop: 0
                                                    }}
                                                    onClick={() => { handleOpenCMS(cms) }}
                                                >
                                                    <Box sx={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        <Icon icon="carbon:cloud-monitoring" width="70" />
                                                    </Box>
                                                    <Box sx={{ mt: 2, mb: 2 }}>
                                                        <center>
                                                            <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                                {cms.cmsName}
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                {cms.departmentName}
                                                            </Typography>
                                                        </center>
                                                    </Box>
                                                    {/* <Popover
                                                        open={Boolean(openView)}
                                                        anchorEl={openView}
                                                        onClose={handleCloseMenu}
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        PaperProps={{
                                                            sx: {
                                                                p: 1,
                                                                width: 140,
                                                                '& .MuiMenuItem-root': {
                                                                    px: 1,
                                                                    typography: 'body2',
                                                                    borderRadius: 0.75,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleClickView}>
                                                            <Iconify icon={'mdi:account-details'} sx={{ mr: 1 }} />
                                                            CMS view
                                                        </MenuItem>

                                                    </Popover> */}
                                                    <Divider />
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleOpenEditCms(cms.cmsId)} fullWidth startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" onMouseDown={handleMouseDown} onClick={() => handleDeleteCms(cms.cmsId)} fullWidth startIcon={<Delete />}>Delete</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No CMS Found
                                </center>
                            )
                        }
                    </CardContent>
                </Card>
            </Container>

            <div>
                <Dialog open={open} onClose={handleCloseCMS} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
                                    <Tab
                                        icon={<Bed />}
                                        iconPosition="start"
                                        label="Bed Details"
                                        id="tab-0"
                                    />
                                </Tabs>
                            }
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseCMS}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <TabPanel value={tabValue} index={0}>
                                {
                                    bedLoading ? (
                                        <center>
                                            <CircularProgress />
                                        </center>
                                    ) : bedAssigned.length > 0 ? (
                                        <Grid container spacing={2}>
                                            {bedAssigned.map((bed) => (
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <FormControlLabel
                                                        key={bed.bedId}
                                                        control={<Checkbox
                                                            name={bed.bedNo}
                                                            value={bed.bedId}
                                                            id={bed.bedId}
                                                            checked={selectedBedToDeselect.some(
                                                                (d) => d.id === bed.bedId
                                                            )}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                const bedId = bed.bedId;
                                                                const bedNo = bed.bedNo;
                                                                if (checked) {
                                                                    setSelectedBedToDeselect([
                                                                        ...selectedBedToDeselect,
                                                                        { id: bedId, bedNo: bedNo }
                                                                    ]);
                                                                } else {
                                                                    setSelectedBedToDeselect((prevState) =>
                                                                        prevState.filter(
                                                                            (d) => d.id !== bedId
                                                                        )
                                                                    );
                                                                }
                                                                console.log(selectedBedToDeselect)
                                                            }}
                                                        />}
                                                        label={<>
                                                            <Card key={bed.bedId} sx={{
                                                                minWidth: 150,
                                                                backgroundColor: "#F4F6F8",
                                                            }}>
                                                                <CardContent>
                                                                    <Box sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                        <Icon icon="tabler:bed-filled" width="80" />
                                                                    </Box>
                                                                    <Divider />
                                                                    <Box sx={{
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <center>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                <Iconify icon={'material-symbols:ward-rounded'} /> {" "} : {" "} {bed.wardNo}
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                <Iconify icon={'icon-park-outline:hospital-bed'} /> {" "} : {" "} {bed.bedNo}
                                                                            </Typography>
                                                                        </center>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                        </>}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <center>
                                            No Beds Added
                                        </center>
                                    )
                                }

                            </TabPanel>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" disabled={!selectedBedToDeselect.length > 0} onMouseDown={handleMouseDown} onClick={handleDeselectBed} fullWidth startIcon={<Iconify icon={'material-symbols:cancel-presentation'} />}>Remove</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleOpenAddMoreBed} fullWidth startIcon={<Iconify icon={'icon-park-outline:hospital-bed'} />}>Add More</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openAddCms} onClose={handleCloseAddCms} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Add CMS
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddCms}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        <CardContent>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="name"
                                    label="CMS Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'carbon:cloud-monitoring'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                                <TextField
                                    name="department"
                                    label="Department"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'ri:hospital-fill'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={departmentError}
                                    helperText={departmentError && "Department is required"}
                                />
                                <TextField
                                    name="cmsId"
                                    label="Cms Id"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'carbon:cloud-monitoring'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={cmsIdError}
                                    helperText={cmsIdError && "Cms Id is required"}
                                />
                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={passwordError}
                                    helperText={passwordError && "Password is required"}
                                />
                                <TextField
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showConfirmPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={confirmPasswordError}
                                    helperText={confirmPasswordError && "Password must match"}
                                />
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddCms} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAddCMS} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openEditCms} onClose={handleCloseEditCms} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Update CMS
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseEditCms}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        <CardContent>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="name"
                                    label="CMS Name"
                                    value={name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'carbon:cloud-monitoring'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                                <TextField
                                    name="department"
                                    label="Department"
                                    value={department}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'ri:hospital-fill'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={departmentError}
                                    helperText={departmentError && "Department is required"}
                                />
                                <TextField
                                    name="cmsId"
                                    label="Cms Id"
                                    value={cmsId}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'carbon:cloud-monitoring'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={cmsIdError}
                                    helperText={cmsIdError && "Cms Id is required"}
                                />
                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={passwordError}
                                    helperText={passwordError && "Password is required"}
                                />
                                <TextField
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showConfirmPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={confirmPasswordError}
                                    helperText={confirmPasswordError && "Password must match"}
                                />
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddCms} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleUpdateCMS} fullWidth >Update</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openAddMoreBed} onClose={handleCloseAddMoreBed}>
                    <CardHeader
                        title="Add Bed"
                        action={
                            <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddMoreBed}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {
                            difference.length > 0 ?
                                <>
                                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 12, md: 12 }}>
                                        {difference.map((bed) => (
                                            <Grid item xs={6} sm={6} md={6}>
                                                <FormControlLabel
                                                    key={bed.bedId}
                                                    control={<Checkbox
                                                        name={bed.bedNo}
                                                        value={bed.bedId}
                                                        id={bed.bedId}
                                                        checked={selectedBedstoAssign == bed.bedId}
                                                        onChange={(e) => {
                                                            const checked = e.target.checked;
                                                            const bedId = bed.bedId;
                                                            if (checked) {
                                                                setSelectedBedstoAssign(bedId);
                                                            } else if (selectedBedstoAssign === bedId) {
                                                                setSelectedBedstoAssign('');
                                                            }
                                                            console.log(selectedBedstoAssign)
                                                        }}
                                                    />}
                                                    label={<>
                                                        <Card key={bed.bedId} sx={{
                                                            minWidth: 150,
                                                            backgroundColor: bed.mapStatus === 'Active' ? "#AFE1AF" : ""
                                                        }}>
                                                            <CardContent>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <Icon icon="tabler:bed-filled" width="80" />
                                                                </Box>
                                                                <Divider />
                                                                <Box sx={{
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <center>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                            <Iconify icon={'material-symbols:ward-rounded'} /> {" "} : {" "} {bed.wardNo}
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                            <Iconify icon={'icon-park-outline:hospital-bed'} /> {" "} : {" "} {bed.bedNo}
                                                                        </Typography>
                                                                    </center>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>

                                                    </>}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <CardActions>
                                        <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddCms} fullWidth >Cancel</Button>
                                        <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAddMoreBed} fullWidth >Submit</Button>
                                    </CardActions>
                                </> :
                                <center> No Bed Found</center>
                        }
                    </CardContent>
                </Dialog>
            </div>

        </div>
    )
}

export default ManageCMS
