import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Home } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';

//services
import { Icon } from '@iconify/react';
import ApiCalls from '../../services/try';

//internals
import UserIcon from "../../assets/images/avatars/user.png";
import Iconify from '../../components/iconify/Iconify';

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


//-------------------------------------------------------------------------------

const Profile = () => {

    const [userData, setUserData] = useState({
        image: '',
        firstName: '',
        lastName: '',
        userEmail: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',

    });
    const [value, setValue] = useState(0);

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [ConfirmPasswordError, setConfirmPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [loadProfile, setLoadProfile] = useState(true);
    const [redirectToList, setRedirectToList] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

    const getUserDetails = () => {
        setLoadProfile(true);
        var roleId = secureLocalStorage.getItem("roleId");
        var Id = secureLocalStorage.getItem("UUID");
        ApiCalls.getdetails(+ roleId + "/" + Id + "/getdettt")
            .then((response) => {
                console.log(response.data);
                if (response.data.userSchemas != undefined) {
                    setLoadProfile(false);
                    setUserData(response.data.userSchemas[0])
                } else {
                    setLoadProfile(false);
                    setUserData([]);
                }
            })
    }

    useEffect(() => {
        getUserDetails();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setUserData({ ...userData, image: e.target.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    setPhoneErrorMessage("Phone Number is required");
                    break;
                case 'currentPassword':
                    setCurrentPasswordError(true);
                    break;
                case 'newPassword':
                    setNewPasswordError(true);
                    setNewPasswordErrorMessage("Enter a new Password")
                    break;
                case 'confirmPassword':
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMessage("Enter Confirm Password")
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setUserData({
            ...userData,
            [name]: value,
        });

        // Reset error state for the corresponding field
        switch (name) {
            case 'firstName':
                setFirstNameError(false);
                break;
            case 'lastName':
                setLastNameError(false);
                break;
            case 'phone':
                setPhoneError(false);
                break;
            // Add cases for other fields if needed
            default:
                break;
        }
    };

    const handleUpdate = () => {

        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        // Collect validation errors
        const errors = {};

        if (!userData.firstName) {
            errors.firstNameError = true;
        }
        if (userData.hasOwnProperty('lastName') && !userData.lastName) {
            errors.lastNameError = true;
        }
        if (!userData.phone) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Phone Number is required";
        } else if (!phoneRegex.test(userData.phone)) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Invalid Phone Number";
        }

        // Display errors if any
        if (Object.keys(errors).length > 0) {
            // Set all errors at once
            setFirstNameError(errors.firstNameError || false);
            setLastNameError(errors.lastNameError || false);
            setPhoneError(errors.phoneError || false);
            setPhoneErrorMessage(errors.phoneErrorMessage || "");
            return; // Stop further execution
        } else {

            console.log(userData);
            var roleId = secureLocalStorage.getItem("roleId");
            var Id = secureLocalStorage.getItem("UUID");
            ApiCalls.edit(+ roleId + "/" + Id + "/editprofile", userData)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Profile Details Updated", {
                            icon: "success",
                            timer: 1000,  // This will make the alert close after 1000 milliseconds (1 second)
                        });
                        getUserDetails();
                        setRedirectToList(true);
                    } else {
                        swal("Error Ocuured", {
                            icon: "error",
                        });
                    }
                })
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleChangePassword = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'currentPassword':
                setCurrentPasswordError(false);
                setCurrentPassword(value);
                break;
            case 'newPassword':
                if (value.length < 8) {
                    setNewPasswordError(true);
                    setNewPasswordErrorMessage("Password must be 8 or more characters in length");
                } else if (value === currentPassword) {
                    setNewPasswordError(true);
                    setNewPasswordErrorMessage("New Password cant be same as Current Password");
                } else {
                    setNewPasswordError(false);
                    setNewPasswordErrorMessage("");
                }
                setNewPassword(value);
                break;
            case 'confirmPassword':
                if (value.length < 8) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMessage("Password must match");
                } else if (value !== newPassword) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMessage("Password must match");
                } else {
                    setConfirmPasswordError(false);
                    setConfirmPasswordErrorMessage("");
                }
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };


    const handleUpdatePassword = () => {
        // Check if current password is provided
        if (!currentPassword) {
            setCurrentPasswordError(true);
            return;
        }

        // Check if new password is provided
        if (!newPassword) {
            setNewPasswordError(true);
            return;
        }

        // Check if confirm password is provided
        if (!confirmPassword) {
            setConfirmPasswordError(true);
            return;
        }

        // Check if new password and confirm password match
        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(true);
            return;
        }

        // Check if current password and new password are the same
        if (currentPassword === newPassword) {
            setNewPasswordError(true);
            return;
        }

        // Perform the password update API call
        const roleId = secureLocalStorage.getItem("roleId");
        const Id = secureLocalStorage.getItem("UUID");
        const s = {
            userName: secureLocalStorage.getItem("userMailId"),
            password: currentPassword,
            newPassword: confirmPassword,
        };

        console.log(s);

        ApiCalls.register(`${roleId}/${Id}/password`, s)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal("Password Updated Successfully", {
                        icon: "success",
                    });
                    // Reset the form after successful password update
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');

                    // Reset error states
                    setCurrentPasswordError(false);
                    setNewPasswordError(false);
                    setConfirmPasswordError(false);
                    setShowConfirmPassword(false);
                    setShowNewPassword(false);
                    setShowPassword(false);
                }
            })
            .catch((error) => {
                console.log(error);
                swal({
                    title: "Incorrect Password",
                    text: "Please try again",
                    icon: "error",
                });
            });

        // Reset the form after successful password update
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');

        // Reset error states
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        setConfirmPasswordError(false);
        setShowConfirmPassword(false);
        setShowNewPassword(false);
        setShowPassword(false);
    };

    if (redirectToList) {
        return <Redirect to={
            secureLocalStorage.getItem("roleId") === "8"
                ? "/mydashboard"
                : "/dashboard"
        } />;
    }

    const handleImageDelete = e => {
        setUserData({ ...userData, image: undefined });
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Profile" />
                    </Breadcrumbs>
                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Card
                            sx={{
                                padding: "20px",
                                borderRadius: "30px",
                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 30px",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                maxWidth: "500px",
                                mb: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <label
                                    htmlFor="user-profile"
                                    style={{
                                        position: "relative",
                                        width: "150px",
                                        height: "150px",
                                        borderRadius: "100%",
                                        display: "flex",
                                        cursor: "pointer",
                                        marginTop: "5px",
                                        outline: "10px solid white",
                                    }}
                                >
                                    {loadProfile ? (
                                        <Skeleton
                                            variant="circular"
                                            width={150}
                                            height={150}
                                            animation="wave"
                                        />
                                    ) : (
                                        <img
                                            src={userData.image === undefined ? UserIcon : userData.image}
                                            alt=""
                                            style={{
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "100%",
                                            }}
                                        />
                                    )}
                                    {userData.image !== undefined && (
                                        <div
                                            className="profile-icon-box remove-icon"
                                            style={{
                                                position: "absolute",
                                                left: "5px",
                                                bottom: "0",
                                                borderRadius: "100%",
                                                padding: "5px 4.5px",
                                                outline: "5px solid white",
                                                display: "flex",
                                                backgroundColor: "#d32f2f",
                                                cursor: "pointer",
                                            }}
                                            onClick={handleImageDelete}
                                        >
                                            <Icon icon={'mdi:trash-can-outline'} width="25" color="white" />
                                        </div>
                                    )}
                                    <div
                                        className="profile-icon-box"
                                        style={{
                                            position: "absolute",
                                            right: "5px",
                                            bottom: "0",
                                            borderRadius: "100%",
                                            padding: "5px 4.5px",
                                            outline: "5px solid white",
                                            display: "flex",
                                            backgroundColor: "#1976d2",
                                        }}
                                        onClick={() => document.getElementById('user-profile-input').click()}
                                    >
                                        <Icon icon={'mdi:edit-outline'} width="25" color="white" />
                                    </div>
                                    <input type="file" className="d-none" id="user-profile-input" onChange={handleImageUpload} />
                                </label>
                                <h1
                                    className="username"
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bolder",
                                        marginTop: "20px",
                                        marginBottom: "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    {userData.firstName + (userData.lastName ? " " + userData.lastName : "")}
                                </h1>
                                <h4
                                    className="user-email"
                                    style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "lighter",
                                        textAlign: "center",
                                    }}
                                >
                                    {userData.userEmail}
                                </h4>
                            </Box>
                        </Card>
                        <Card sx={{
                            padding: "20px",
                            borderRadius: "30px",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 30px",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: "500px",
                        }}>
                            <CardHeader
                                title={
                                    <Tabs
                                        value={value}
                                        onChange={handleTabChange}
                                        onMouseDown={handleMouseDown}
                                        aria-label="icon label tabs example"
                                        orientation="vertical"
                                        sx={{
                                            '& .MuiTabs-flexContainer': {
                                                flexDirection: 'column',
                                            },
                                        }}
                                    >
                                        <Tab
                                            icon={<Icon icon={'fa-solid:user-edit'} />}
                                            label="Profile"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                fontSize: 18,
                                            }}
                                        />
                                        <Tab
                                            icon={<Icon icon={'solar:lock-password-bold'} />}
                                            label="Change Password"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                fontSize: 18,
                                            }}
                                        />
                                    </Tabs>
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                        <Card sx={{
                            padding: "20px",
                            borderRadius: "30px",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 30px",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: '100%'
                        }}>
                            <CardContent>
                                <TabPanel value={value} index={0}>
                                    <Box sx={{ width: 1 }}>
                                        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                            <Box gridColumn="span 12">
                                                <Item>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>


                                                        {secureLocalStorage.getItem("roleId") === "4" || secureLocalStorage.getItem("roleId") === 4 ? (
                                                            <TextField
                                                                name="firstName"
                                                                label={
                                                                    <span>
                                                                        {secureLocalStorage.getItem("CompanyName") + " Name"}{' '}
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </span>
                                                                }
                                                                value={userData.firstName}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                fullWidth
                                                                error={firstNameError}
                                                                helperText={firstNameError && secureLocalStorage.getItem("CompanyName") + " Name is Required"}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        ) : <>
                                                            <TextField
                                                                name="firstName"
                                                                label={
                                                                    <span>
                                                                        First Name{' '}
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </span>
                                                                }
                                                                value={userData.firstName}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={firstNameError}
                                                                helperText={firstNameError && "First Name is required"} /><TextField
                                                                name="lastName"
                                                                label={
                                                                    <span>
                                                                        Last Name{' '}
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </span>
                                                                }
                                                                value={userData.lastName}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={lastNameError}
                                                                helperText={lastNameError && "Last Name is required"} />
                                                        </>}
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                        <TextField
                                                            name="userEmail"
                                                            label={
                                                                <span>
                                                                    Email{' '}
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </span>
                                                            }
                                                            value={userData.userEmail}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            name="phone"
                                                            label={
                                                                <span>
                                                                    Phone{' '}
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </span>
                                                            }
                                                            value={userData.phone}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={phoneError}
                                                            helperText={phoneError && phoneErrorMessage}
                                                        />
                                                    </Stack>
                                                </Item>
                                            </Box>
                                            <Box gridColumn="span 12">
                                                <Item>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                                        <TextField
                                                            name="street"
                                                            label="Street Address"
                                                            value={userData.street}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                        />
                                                        <TextField
                                                            name="city"
                                                            label="City"
                                                            value={userData.city}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                        />
                                                        <TextField
                                                            name="state"
                                                            label="State"
                                                            value={userData.state}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                        />
                                                    </Stack>
                                                </Item>
                                            </Box>
                                            <Box gridColumn="span 12">
                                                <Item>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                                        <TextField
                                                            name="postalCode"
                                                            label="Postal Code"
                                                            value={userData.postalCode}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                        />
                                                        <TextField
                                                            name="country"
                                                            label="Country"
                                                            value={userData.country}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={handleChange}
                                                        />
                                                        <Button
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            color='error'
                                                            sx={{
                                                                borderRadius: "12px",
                                                            }}
                                                            startIcon={
                                                                <Iconify icon={'icon-park-outline:close'} />
                                                            }
                                                            onClick={() => setRedirectToList(true)}
                                                            onMouseDown={handleMouseDown}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                            sx={{
                                                                borderRadius: "12px",
                                                            }}
                                                            startIcon={
                                                                <Iconify icon={'icon-park-outline:file-editing-one'} />
                                                            }
                                                            onClick={handleUpdate}
                                                            onMouseDown={handleMouseDown}
                                                        >
                                                            Update
                                                        </Button>
                                                    </Stack>
                                                </Item>
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid container sx={{
                                        px: 10
                                    }} >
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                name="currentPassword"
                                                label="Current Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={currentPassword}
                                                fullWidth
                                                sx={{
                                                    mb: 3
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={handleBlur}
                                                onChange={handleChangePassword}
                                                InputProps={{
                                                    autoComplete: 'off',
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Iconify icon={'teenyicons:password-solid'} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDown}
                                                                edge="end"
                                                            >
                                                                <Iconify icon={showPassword ? 'bx:show' : 'bx:hide'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={currentPasswordError}
                                                helperText={currentPasswordError && "Password is required"}
                                            />
                                            <TextField
                                                name="newPassword"
                                                label="New Password"
                                                type={showNewPassword ? 'text' : 'password'}
                                                value={newPassword}
                                                fullWidth
                                                sx={{
                                                    mb: 3
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChangePassword}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                    autoComplete: 'off',
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Iconify icon={'teenyicons:password-solid'} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowNewPassword}
                                                                onMouseDown={handleMouseDown}
                                                                edge="end"
                                                            >
                                                                <Iconify icon={showNewPassword ? 'bx:show' : 'bx:hide'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={newPasswordError}
                                                helperText={newPasswordError && newPasswordErrorMessage}
                                            />
                                            <TextField
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                value={confirmPassword}
                                                fullWidth
                                                sx={{
                                                    mb: 3
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChangePassword}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                    autoComplete: 'off',
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Iconify icon={'teenyicons:password-solid'} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                onMouseDown={handleMouseDown}
                                                                edge="end"
                                                            >
                                                                <Iconify icon={showConfirmPassword ? 'bx:show' : 'bx:hide'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={ConfirmPasswordError}
                                                helperText={ConfirmPasswordError && confirmPasswordErrorMessage}
                                            />
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: "8px",
                                                    marginTop: 3
                                                }}
                                                onClick={handleUpdatePassword}
                                                onMouseDown={handleMouseDown}
                                                disabled={currentPasswordError || newPasswordError || ConfirmPasswordError}
                                                startIcon={
                                                    <Iconify icon={'mdi:password-check'} />
                                                }
                                            >
                                                Reset Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div >
    )
}

export default Profile