import React from 'react';
import Alarm from "../../assets/images/Alarm.png";
import styled from 'styled-components';
import './index.css';

function BottomContainer() {
    return (
        <div style={{ display: 'flex', height: '30vh' }}>
            <div style={{ backgroundColor: '#1D252E', color: '#8C4141', width: '30vw', margin: 10, padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ContentText> NIBP (Auto 15min)  09:20 </ContentText>
                    <ContentText> mmHg </ContentText>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', height: '75%' }}>
                    {/* <p style={{ fontSize: 54 }}> 118 / 68  (85) </p> */}
                    <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <LargeText>  118 / 68  (85) </LargeText>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#1D252E', color: 'white', width: '10vw', margin: 10, padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ContentText> T1 </ContentText>
                    <ContentText> °C </ContentText>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', height: '75%' }}>
                    {/* <p style={{ fontSize: 54 }}> 35.6 </p> */}
                    <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <LargeText>  35.6 </LargeText>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#1D252E', color: 'white', width: '10vw', margin: 10, padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ContentText> T2</ContentText>
                    <ContentText> °C </ContentText>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', height: '75%' }}>
                    {/* <p style={{ fontSize: 54 }}> 35.6 </p> */}
                    <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <LargeText>  35.6 </LargeText>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#1D252E', color: '#FF00E5', width: '25vw', margin: 10, padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ContentText>CGM </ContentText>
                    <ContentText> mg/dL </ContentText>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', height: '75%' }}>
                    {/* <p style={{ fontSize: 54 }}> 140 </p> */}
                    <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <LargeText>  140 </LargeText>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#1D252E', color: '#AC1100', width: '15vw', margin: 10, padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ContentText> Alarm </ContentText>
                </div>
                <div style={{ height: '58%', display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                    <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <img src={Alarm} class="image" alt="Alarm" width="60px" height="50px" style={{ objectFit: 'contain' }} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BottomContainer;

const LargeText = styled.p`
  font-size: 3rem;
`;

const ContentText = styled.p`
  font-size: 1.4rem;
`;